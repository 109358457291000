/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.m {
  display: flex;
  min-height: 358px;
  margin-bottom: 16px;
}
.pic {
  margin-right: 24px;
  width: 380px;
}

.info {
  width: calc(100% - 330px);

  .divider {
    width: 1px;
    background-color: #e0e0e0;
    height: calc(100% - 4px);
    margin: 2px 18px;
  }

  .tag_brand_img {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: 1px solid rgba(7, 14, 50, 0.1);
    border-radius: 2px;
    min-height: 26px;
    .tag_brand_wrap {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      min-width: 51px;
      background-color: rgba(7, 14, 50, 0.1);
      border-top-left-radius: 1px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 1px;
      font-size: 14px;
      line-height: 1;
      color: rgba(7, 14, 50, 0.6);
      text-align: left;
    }
    .tag_brand_value {
      display: flex;
      align-items: center;
    }
    .tag_brand_logo {
      height: 18px;
      margin: 0 16px;
    }
    .tag_brand_name {
      margin: 0 16px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .product_name {
    flex-shrink: 0;
    margin: 18px 0;
    font-size: 20px;
    line-height: 1.3;
    color: rgb(7, 14, 50);
    text-align: left;
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    webkit-line-clamp: 3;
    webkit-box-orient: vertical;
  }

  .inventoryModule {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    height: 32px;
    background-color: rgb(255, 246, 237);
    border: 1px solid #fdebda;
    border-radius: 4px;
    padding-left: 17px;
    &.inventoryModuleMargin {
      margin-bottom: 18px;
    }
    &.inventoryModuleHide {
      display: none;
    }
    .inventoryInfo {
      height: 22px;
      margin-left: 8px;
      .inventoryText {
        flex-shrink: 0;
        font-size: 14px;
        line-height: 1.57;
        color: rgb(7, 14, 50);
      }
      .inventoryNumber {
        color: rgb(225, 37, 27);
      }
    }
  }

  .priceContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100px;
    border-bottom: 1px solid rgba(7, 14, 50, 0.05);
    .priceRow {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      height: 26px;
      margin-right: 50px;
    }
    .priceLabel {
      flex-shrink: 0;
      margin-left: 4px;
      font-size: 14px;
      line-height: 1.29;
      color: rgba(7, 14, 50, 0.5);
      text-align: left;
    }
    .priceWrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      height: 26px;
      color: rgb(225, 37, 27);
    }
    .priceAmount {
      flex-shrink: 0;
      margin-left: 1px;
      font-size: 26px;
      line-height: 1;
      text-align: left;
    }
    .currencySymbol {
      flex-shrink: 0;
      font-size: 18px;
      line-height: 1.17;
      text-align: left;
    }
    .priceSection {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-self: flex-start;
      height: 26px;
      margin-top: 13px;
    }
    .taxInfoSection {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-self: flex-start;
      height: 14px;
      margin-top: 22px;
      .productInfoRow {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 14px;
      }
      .productIdIcon {
        width: 10px;
        height: 10px;
        margin-left: 6px;
      }
      .productId {
        flex-shrink: 0;
        margin-left: 6px;
        font-size: 14px;
        line-height: 1;
        color: rgb(7, 14, 50);
        text-align: left;
        letter-spacing: 0px;
      }
      .img1 {
        width: 1px;
        height: 10px;
        margin-left: 18px;
      }
      .taxRow {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 14px;
      }
      .taxRate {
        flex-shrink: 0;
        margin-left: 6px;
        font-size: 14px;
        line-height: 1;
        color: rgb(7, 14, 50);
        text-align: left;
      }
      .productIdLabel,
      .taxLabel {
        flex-shrink: 0;
        font-size: 14px;
        line-height: 1;
        color: rgba(7, 14, 50, 0.5);
        text-align: left;
        letter-spacing: 0px;
      }
      //
    }
  }

  &_box {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }

  .tag {
    display: flex;

    &_brand {
      cursor: pointer;
      padding: 3px;
      border: 1px solid rgba(0, 3, 20, 0.15);
      border-radius: 2px;
      background: #fff;
      font-size: 14px;
      color: rgba(0, 3, 20, 0.85);
      display: flex;
      align-items: center;
      margin-right: 8px;
      margin-bottom: 6px;

      &_title {
        background: rgba(0, 3, 20, 0.05);
        border-radius: 1px 0 0 1px;
        color: rgba(0, 3, 20, 0.85);
        font-size: 14px;
        line-height: 20px;
        padding: 2px 4px;
      }

      &_name {
        padding: 2px 8px;
        padding-right: 5px;
        line-height: 20px;
        box-sizing: border-box;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &_brand:hover {
      border: 1px solid rgba(25, 56, 237, 1);
    }
  }

  .descriptions {
    margin-top: 12px;
  }
}

.model {
  display: flex;
  border: 1px solid rgba(94, 133, 193, 1);
  em,
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  em {
    padding: 0 5px;
    background: rgba(13, 71, 160, 1);
    color: white;
    font-weight: 600;
  }
  span {
    width: 36px;
    background: rgba(243, 248, 255, 1);
    color: #0d47a0;
    font-weight: 700;
  }
}
.brand {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
  color: #666;
  padding: 0 5px;
  background-color: rgba(245, 245, 245, 1);
}
.name {
  display: flex;
  justify-content: space-between;
  line-height: 25px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 3, 20, 1);
}
.material_name {
  display: flex;
  line-height: 25px;
  font-size: 12px;
  font-weight: 500;
  color: #999;
  margin-right: 76px;
}
.productName {
  line-height: 25px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 3, 20, 1);
  margin-right: 300px;
}

/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.nav {
  width: 100%;
  padding: 5px 40px;
  background: var(--header-bg);
  :global {
    .ant-drawer-header {
      padding: 12px 24px;
    }
    .ant-drawer-title {
      height: 40px;
      img {
        cursor: pointer;
      }
    }
  }
}

.rightTextColor {
  color: var(--controller-tools-text-right-color);
  margin-left: 20px;
  flex-shrink: 0;
}

.flagContainer {
  display: flex;
  align-items: center;
  .flagImage {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}

.navContent {
  margin: 0 auto;
  max-width: var(--page-max-width);
  min-width: var(--page-min-width);
  height: 60px;
  background: var(--header-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    width: 138px;
    background-image: var(--header-logo);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    box-sizing: content-box;
    padding: 0;
  }
  > ul {
    display: flex;
    align-items: center;
    height: 100%;
    li {
      font-size: 14px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 30px;
      &:not(.category):not(.spacer):not(.logo):hover {
        background: #00678B;
      }
      i {
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 12px;
        background: url('../../assets/images/home/images/tong.png') 0 0 / 100% 100%;
        vertical-align: sub;
        // background: red;
      }
      a {
        font-size: 14px;
        font-weight: normal;
        font-family: Arial;
        color: #fff;
      }
    }
    .spacer {
      width: 1px;
      height: 12px;
      background: var(--header-split-line-color);
      overflow: hidden;
      // margin-left: 33px;
      opacity: 0.4;
      cursor: default;
      vertical-align: -2px;
      padding: 0;
    }
    .category_name {
      // margin-left: 20px;
      // padding-left: 0;
      min-width: 130px;
    }

    .category {
      min-width: 130px;
      height: 40px;
      border: 1px #fff solid;
      margin: 0;
      margin-right: 16px;
      /* background: red; */
      padding: 0 15px;
      .img {
        width: 15px;
        height: 15px;
        // position: absolute;
        // top: 14px;
        display: inline-block;
        border-radius: 2px;
        background: url('../../assets/images/logo/cateLogo.png') 0 0 / 100% 100%;
        // &:hover{
        //   background: #00678B url("../../assets/images/homeImg/home_category_icon_hover.png")  center center / 16px 16px no-repeat;
        // }
      }
    }
  }
  .con_search {
    // float: left;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .order {
    position: relative;
  }
  .shop {
    // display: flex;
    height: 54px;
    .fr {
      height: 100%;
      > ul {
        height: 100%;
        display: flex;
        align-items: center;
        > li {
          cursor: pointer;
          &:not(.spacer) {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 23px;
            justify-content: center;
            border: 1px solid transparent;
            &:hover {
              border: 1px solid rgba(255, 255, 255, 0.8);
              border-radius: 2px;
            }
          }
          > span {
            cursor: pointer;
          }
        }

        .spacer {
          width: 1px;
          height: 12px;
          background: #fff;
          opacity: 0.4;
          overflow: hidden;
          cursor: default;
          vertical-align: -2px;
        }

        .exit_box {
          min-width: 151px;
          position: relative;
          cursor: pointer;
          // height: 80px;
          // line-height: 80px;
          // top: 4px;
          // right: 290px;

          span {
            color: #fff;
            cursor: pointer;
          }

          ul {
            position: absolute;
            top: 52px;
            z-index: 9999;
            right: -1px;
            display: none;
            // width: 110px;
            // border: 1px solid rgba(0, 3, 20, 0.05);
            box-shadow: 0 4px 8px 0 rgba(0, 3, 20, 0.05);
            border-radius: 4px;
            background-color: #fff;
            padding: 16px 16px 0;
            &::after {
              content: '';
              display: block;
              position: absolute;
              border: 10px solid #fff;
              border-color: transparent transparent #fff transparent;
              top: -20px;
              right: 25px;
            }
            li {
              // text-align: center;
              line-height: 16px;
              background: rgba(255, 255, 255, 1);
              color: #fff;
              margin-bottom: 16px;
              &.tips {
                white-space: nowrap;
                color: rgba(0, 0, 0, 0.85);
                &:hover {
                  color: rgba(0, 0, 0, 0.85);
                }
              }
              &:hover {
                color: #00678b;
                text-decoration: underline;
              }
            }
          }
          .exit {
            color: #070e32;
            display: flex;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
            span {
              color: #000;
              &:hover {
                color: #00678b;
                text-decoration: underline;
              }
            }
          }
        }

        > li {
          display: inline-block;
          font-size: 14px;
          color: #fff;

          > i {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 4px;
            vertical-align: -5px;
          }

          a {
            color: #fff;
            &:hover,
            &:active {
              color: #fff;
            }
          }

          > span {
            display: inline-block;
            // cursor: default;
          }

          .user_icon {
            position: relative;
            background: url('../../assets/images/homeImg/user_person_icon.png') 0 0 / 100% 100%;
            width: 18px;
            height: 18px;
            top: 0px;
            margin-right: 6px;
          }

          .user {
            position: relative;
            background: url('../../assets/images/homeImg/open_icon.png') 0 0 / 100% 100%;
            width: 8px;
            height: 7px;
            margin-left: 8px;

            &.act {
              background: url('../../assets/images/header/header_user_fff.png') 0 0 / 100% 100%;
            }
          }
          .languageMenu {
            min-width: 140px;
          }
        }
      }
    }
    .btnIcon_1 {
      width: 40px;
      height: 100%;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      cursor: pointer;
      // &:hover{
      //   background: #fff;
      //   border: 1px solid rgba(67,93,240,0.45);
      //   color: rgba(67,93,240,1);
      //   .shopIcon_1 {
      //     background:  url("../../assets/images/search/icon_hover_shopCar.png") 0 0 / 100% 100%;
      //   }
      // }
    }
  }

  .shopIcon_1 {
    width: 40px;
    height: 37px;
    position: absolute;
    display: inline-block;
    @include background-image(--header-car-image);
    background-size: 27px;
  }
  .ci_count {
    position: absolute;
    top: 9px;
    right: -10px;
    display: inline-block;
    padding: 0px 4px;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    font-weight: 500;
    background-color: #e1251b;
    border-radius: 10px;
    min-width: 12px;
    text-align: center;
    font-style: normal;
  }
}

.container {
  width: 100%;
  height: 38px;
  background-color: var(--controller-tools-bg);
  padding: 0 40px;
  font-size: 14px;
  .containerInfo {
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: var(--page-max-width);
    min-width: var(--page-min-width);
    color: rgb(255, 255, 255);

    .quickBtns {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      height: 100%;
    }
    .spacer {
      display: inline-block;
      width: 1px;
      height: 12px;
      background: var(--header-split-line-color);
      overflow: hidden;
      margin: 0 28px;
      opacity: 0.4;
      cursor: default;
      vertical-align: -2px;
      padding: 0;
    }

    .text {
      flex-shrink: 0;
      margin-top: -2px;
      font-size: 14px;
      line-height: 1.14;
      color: rgb(255, 255, 255);
      text-align: left;
    }

    .arrowIcon {
      width: 1px;
      height: 9px;
    }

    .categoriesIcon {
      width: 14px;
      height: 10px;
      margin-top: -2px;
    }

    .categoriesText {
      margin-left: 10px;
      white-space: nowrap;
      cursor: pointer;
    }

    .containerInfoItem {
      padding: 8px 10px;
      border: 1px solid transparent;
      border-radius: 2px;
      margin: 3px 0;
      &:hover {
        border-color: #fff;
      }
    }

    .containerInfoItem {
      white-space: nowrap;
      cursor: pointer;
    }

    .accountText {
      white-space: nowrap;
    }

    .leftArrow {
      margin-left: 28px;
    }

    .rightArrow {
      margin-left: 12px;
    }
  }
}

/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.content {
  //width: 940px;
  font-size: 12px;
  position: relative;
  float: right;
  .larger_img {
    margin: 0 auto;
    // padding: 0 0 10px 0;
    overflow: hidden;
    position: relative;
    .gl_warp {
      padding-top: 8px;
      overflow: hidden;
      .gl_item {
        width: 233px;
        margin: 0 8px 8px 0px;
        box-sizing: border-box;
        position: relative;
        float: left;
        background-color: #fff;
        &:hover{
          box-shadow:  0 8px 16px 0 rgba(0,3,20,0.05);
          transform: translate3d(0,-3px,0);
        }
        &:nth-child(5n + 5) {
          margin-right: 0;
        }
        img {
          cursor: pointer;
        }
        .title {
          width: 210px;
          padding-left: 12px;
          line-height: 17px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          color: #333;

          &:hover {
            >a {
              color: #0d47a0;
            }
          }

          >a {
            font-size: 12px;
            color: #333;
          }
        }
        .title_1 {
          width: 210px;
          height: 32px;
          padding-left: 12px;
          font-weight: normal;
          color: rgba(0,0,0,0.85);
          line-height: 17px;
          margin: 8px 0;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 12px;
          font-weight: normal;
          color: rgba(0,0,0,0.85);

          &:hover {
            >a {
              color: #00678B;
            }
          }

          >a {
            font-size: 12px;
            color: rgba(0,0,0,0.85);
          }
        }

        .order_Num,
        .model_Num {
          font-size: 12px;
          line-height: 17px;
          color: rgba(0,0,0,0.65);
          // margin-top: 2px;
          padding-left: 12px;
          cursor: default;
        }
        .text_space {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .ent_price {
          margin: 12px 0 0px 12px;
          font-size: 20px;
          font-weight: 600;
          color: #e50c00;
          height: 20px;
          font-weight: normal;
          cursor: default;
          em {
            font-size: 16px;
            font-style: normal;
            margin-right: 2px;
            font-weight: bold;
          }
          .jd_price {
            //margin: 0 0 2px 12px;
            line-height: 22px;
            font-size: 12px;
            color: #999;
            cursor: default;
            float: right;
            padding-right: 8px;
            font-weight: normal;
          }
        }

        .tags {
          height: 20px;
          margin: 6px 0 12px 8px;
          display: flex;
          justify-content: left;
          cursor: default;

          span {
            display: inline-block;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: normal;
            color: rgba(229, 12, 0, 1);
            border: 1px solid #F6AAA6;
            margin-left: 4px;
            padding: 4px;
            text-align: center;
            &.biaopin{
              color: #FFFFFF;
              border: none;
              background: linear-gradient(90deg, rgba(111,131,242,1) 0%,rgba(67,93,240,1) 100%);
            }
            &.ziying{
              color: #FFFFFF;
              border: none;
              background: rgba(229,12,0,1);
            }
          }
        }

        .operation {
          margin-top: 12px;
          padding-left: 12px;
          border-top: 1px solid #ededed;
          display: flex;
          align-items: center;
          justify-content: center;

          // .contrast {
          //   display: inline-block;
          //   width: 70px;
          //   border-right: 1px solid #ededed;

          //   .in_label {
          //     width: 70px;
          //     height: 32px;
          //     line-height: 32px;
          //     text-align: center;
          //     display: inline-block;
          //     cursor: pointer;

          //     &:hover {
          //       color: #0d47a0;
          //     }

          //     .icon {
          //       width: 14px;
          //       height: 14px;
          //       display: inline-block;
          //       border: 1px solid rgba(204, 204, 204, 1);
          //       vertical-align: sub;
          //       margin-right: 6px;
          //     }

          //     input {
          //       opacity: 0;
          //     }

          //     input[type='checkbox']:checked+i {
          //       border: none;
          //       background: url('/assets/images/searchList/checked.png') 0 0 / 100% 100%;
          //     }
          //   }
          // }

          .add_shop {
            display: inline-block;
            width: 148px;
            height: 42px;
            line-height: 42px;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            color: rgba(25,56,237,1);
            vertical-align: top;
            cursor: pointer;
            i{
              vertical-align: sub;
              width: 14px;
              height: 14px;
              margin-right: 6px;
              display: inline-block;
              background: url("../../../../assets/images/international/searchList/car_search_icon.png") 0 0 / 100% 100%;
            }
            // &:hover {
            //   background-color: rgba(13, 71, 160, 1);
            //   color: #fff;
            // }
          }
        }

        .stock {
          position: absolute;
          top: 206px;
          left: 0;
          z-index: 3;
          height: 24px;
          line-height: 24px;
          width: 240px;
          overflow: hidden;
          padding-left: 10px;
          color: #fff;
          //filter: progid:DXImageTransform.Microsoft.gradient(enabled='true', startColorstr='#99000000', endColorstr='#99000000');
          background: rgba(0, 0, 0, 1);
          opacity: 0.35;
          //transition: all 0.5s ease;
          font-size: 12px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
    .pagination {
      text-align: right;
      width: 1200px;
      margin: 0px auto 72px;
    }
  }
}

/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.mainProgress {
  width: 100%;
  height: 100%;
  .orderInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;

    .orderDateRow {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: baseline;
      align-self: flex-start;
      margin-top: 15px;
      margin-left: 1px;
    }

    .dateValue {
      flex-shrink: 0;
      font-size: 14px;
      line-height: 2;
      color: rgb(7, 14, 50);
    }

    .dateLabel {
      flex-shrink: 0;
      font-size: 14px;
      line-height: 2;
      color: rgba(7, 14, 50, 0.5);
      margin-right: 6px;
    }

    .orderNumbersRow {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items:  flex-end;
    }

    .parentOrderWrapper {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      position: relative;
      top: -2px;
    }

    .parentOrderNumber {
      flex-shrink: 0;
      font-size: 14px;
      line-height: 2;
      color: rgb(0, 3, 20);
    }

    .parentOrderLabel {
      flex-shrink: 0;
      font-size: 14px;
      line-height: 2;
      color: rgba(0, 3, 20, 0.5);
    }

    .orderNumberWrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: baseline;
      align-self: flex-start;
      margin-right: 30px;
    }

    .orderNumber {
      flex-shrink: 0;
      font-size: 24px;
      line-height: 1.17;
      color: rgb(7, 14, 50);
    }

    .orderLabel {
      flex-shrink: 0;
      font-size: 24px;
      line-height: 1.17;
      color: rgba(7, 14, 50, 0.5);
    }
  }
  .process-text_blob {
    font-weight: 500;
  }

  .progress_info-containter {
    width: 100%;
    border: 1px solid rgba(7, 14, 50, 0.1);
    border-radius: 4px;
    margin-bottom: 20px;
    .tabInfo {
      :global {
        .ant-tabs-nav {
          padding: 0 20px;
          background: rgba(7, 14, 50, 0.03);
          border-radius: 4px 4px 0 0;
          margin-bottom: 0px;
        }
      }
      .tabInfoItem {
        display: flex;
        padding: 22px 0;
      }
      .top {
        width: 100%;
        height: 100%;
        .topContent {
          max-width: 300px;
          height: 50px;
          margin: 0 auto;
          :global {
            .ant-tabs-nav-wrap {
              margin: 0 auto;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .bottom {
        width: 280px;
        padding-top: 17px;
        text-align: center;
        border-right: 1px solid rgba(7, 14, 50, 0.05);
        .progress-text {
          font-size: 24px;
          font-weight: 500;
          color: #333;
          padding-bottom: 30px;
        }
        .black {
          color: #333;
        }
        .orange {
          color: #ed5502;
        }
        .cyanBlue {
          color: #02a112;
        }
        .claret {
          color: #e00224;
        }
        .gray {
          color: #999;
        }
        .normalText {
          font-size: 14px;
          color: #333;
        }
        .mrb16 {
          margin-bottom: 12px;
        }
        .mrt16 {
          margin-top: 12px;
        }
        .progressBtn {
          margin-bottom: 10px;
        }
        .operateBtn {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 14px;
          font-size: 14px;
          color: #333;
          align-items: center;
          .print {
            cursor: pointer;
            background: url('../../../../assets/images/orderDetailNew/icon_print.svg') no-repeat;
            background-size: 14px;
            text-indent: 20px;
          }
          .cancel {
            cursor: pointer;
            margin-bottom: 10px;
            background: url('../../../../assets/images/orderDetailNew/icon_cancel.svg') no-repeat;
            background-size: 14px;
            text-indent: 20px;
          }
        }
      }
    }
    .right {
      flex: 1;
      .orderProgress {
        padding: 0 34px;
        .node {
          float: left;
          width: 88px;
          position: relative;
          top: 54px;
          height: 32px;
          .node_icon {
            position: absolute;
            display: block;
            left: 25px;
            top: 0;
            width: 32px;
            height: 32px;
            overflow: hidden;
          }
          .icon_submit {
            background: url('../../../../assets/images/orderDetailNew/submit.svg') no-repeat center;
          }

          .icon_processing {
            background: url('../../../../assets/images/orderDetailNew/processing.svg') no-repeat center;
          }
          .icon_confirmed {
            background: url('../../../../assets/images/orderDetailNew/confirmed.svg') no-repeat center;
          }
          .icon_pending {
            background: url('../../../../assets/images/orderDetailNew/pending.svg') no-repeat center;
          }
          .icon_shipping {
            background: url('../../../../assets/images/orderDetailNew/shipping.svg') no-repeat center;
          }
          .icon_completed {
            background: url('../../../../assets/images/orderDetailNew/completed.svg') no-repeat center;
          }

          ul {
            position: absolute;
            text-align: center;
            z-index: 1;
            width: 189px;
            margin-left: -50px;
            .txt1 {
              height: 36px;
              margin-bottom: 16px;
              font-size: 12px;
            }
            .txt2 {
              font-size: 14px;
              font-weight: 600;
              line-height: 25px;
              color: #02a112;
              margin-bottom: 2px;
            }
            .txt3 {
              width: 100px;
              color: #666;
              line-height: 15px;
              margin: 0 auto;
            }
          }
        }
        .node_gray {
          .icon_submit {
            background: url('../../../../assets/images/orderDetailNew/submit_gray.svg');
          }
          .icon_processing {
            background: url('../../../../assets/images/orderDetailNew/processing_gray.svg');
          }
          .icon_confirmed {
            background: url('../../../../assets/images/orderDetailNew/confirmed_gray.svg');
          }
          .icon_pending {
            background: url('../../../../assets/images/orderDetailNew/pending_gray.svg');
          }
          .icon_shipping {
            background: url('../../../../assets/images/orderDetailNew/shipping_gray.svg');
          }
          .icon_completed {
            background: url('../../../../assets/images/orderDetailNew/completed_gray.svg');
          }
          .icon_cancel {
            background: url('../../../../assets/images/orderDetailNew/cancelled.svg');
          }

          ul {
            .txt2 {
              font-weight: normal;
              color: #999;
            }
            .txt3 {
              color: #999;
            }
          }
        }
        .process {
          width: 38px;
          height: 1px;
          position: relative;
          top: 70px;
          float: left;
          background: url('../../../../assets/images/orderDetailNew/line.svg');
        }
        .process_gray {
          background: url('../../../../assets/images/orderDetailNew/line_gray.svg');
        }
      }
      .approvalList {
        width: 100%;
        margin: 0 auto;
        overflow-y: scroll;
        padding: 16px 60px 0 65px;
        li {
          display: flex;
          .state {
            display: block;
            min-width: 100px;
            text-align: right;
            font-size: 14px;
            margin: 0 20px 0 0;
            line-height: 26px;
            color: $text-3-color;
          }

          &.suc {
            .detail {
              .nodeIcon {
                .icon {
                  background: #fff url(../../../../assets/images/order_detail/checkbox-circle-fill@2x.png) no-repeat 2px
                    center;
                  background-size: 24px;
                  height: 24px;
                }
                .b {
                  background: #00B960;
                  border-radius: 2px;
                }
              }
            }

            .state {
              color: #3b4;
            }
          }

          &.rej {
            .state {
              color: $red-color;
            }

            .detail {
              .nodeIcon {
                .icon {
                  background: #fff url(../../../../assets/images/order_detail/checkbox-rej-fill@2x.png) no-repeat 2px
                    center;
                  background-size: 24px;
                }
                .b {
                  background: #E1251B;
                  border-radius: 2px;
                }
              }
            }
          }

          .detail {
            flex: 1;
            display: flex;
            overflow: hidden;

            .nodeIcon {
              display: block;
              min-height: 44px;
              position: relative;

              .icon {
                display: block;
                width: 24px;
                height: 24px;
                margin: 2px 20px 2px 0;
                background: #fff url(../../../../assets/images/order_detail/time-line@2x.png) no-repeat 2px center;
                background-size: 16px;
              }

              .b {
                position: absolute;
                width: 2px;
                height: calc(100% - 25px);
                background: linear-gradient(360deg, rgba(231, 231, 231, 0.35) 0%, rgba(0, 0, 0, 0.35) 100%);
                top: 26px;
                left: 13px;
              }

              .bg_opacity {
                position: absolute;
                display: inline-block;
                width: 20px;
                height: calc(100% - 10px);
                top: 26px;
                background: url(../../../../assets/images/order_detail/bg@2x.png) repeat;
              }
            }

            .boxs {
              flex: 1;
              .top {
                display: flex;
                align-items: center;
                height: 24px;
                text-align: left;
                .level {
                  width: 150px;
                  display: block;
                  color: #070E32;
                  font-size: 14px;
                  // line-height: 24px;
                  padding-right: 10px;
                  text-align: left;
                }
                .name {
                  display: block;
                  color: #070E32;
                  font-size: 14px;
                  text-align: left;
                  margin-right: 16px;
                }
                .date {
                  width: 200px;
                  display: block;
                  color: rgba(7, 14, 50, 0.5);
                  font-size: 12px;
                  margin-top: 1px;
                  text-align: left;
                }
              }
            }
          }

          .suggest {
            margin: 9px 0 0 150px;
            width: 300px;
            line-height: 18px;
            padding: 10px 16px;
            box-sizing: border-box;
            background: #F8F8F9;
            border-radius: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            margin-bottom: 12px;
            color: rgba(7, 14, 50, 0.5);
          }

          &:last-child {
            .nodeIcon {
              min-height: 24px;
            }
          }
        }
      }
    }
  }
}

/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.manageConditionModel{
  :global{
    .ant-drawer-header{
      padding: 24px !important;
    }
    .ant-drawer-close{
      padding:20px 14px !important;
    }
    .ant-drawer-body{
      padding: 0 !important;
    }
    .ant-drawer-footer{
      padding:0 24px !important;
      border: none !important;
    }
    .ant-select-selector{
      font-size: 14px !important;
      height: 32px !important;
      line-height: 32px !important;
    }
    .ant-select {
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        font-size: 14px !important;
        line-height: 32px !important;
      }
    }
  }
  .iconClose{
    position: fixed;
    right: 25px;
    width: 16px;
    height: 16px;
    float: right;
    cursor: pointer;
    background: url("../../../../../assets/images/common/icon-close.svg") center no-repeat;
  }
  .manageFooter{
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: right;
    align-items: center;
    .footerLeft{
      flex: 1;
    }
    // .footerRight{
    //   width: 136px;
    //   .primaryBtn{
    //     display: inline-block;
    //     width: 60px;
    //     height: 32px;
    //     line-height: 32px;
    //     text-align: center;
    //     font-size: 14px;
    //     color: #ffffff;
    //     cursor: pointer;
    //     border-radius: 2px !important;
    //     background: #00678B !important;
    //     border: 1px solid #00678B  !important;
    //   }
    //   .primaryBtn:hover{
    //     background: #435DF0 !important;
    //     border: 1px solid #435DF0  !important;
    //   }
    //   .defaultBtn{
    //     display: inline-block;
    //     width: 60px;
    //     height: 32px;
    //     line-height: 32px;
    //     margin-left: 16px;
    //     text-align: center;
    //     cursor: pointer;
    //     color: $text-085-color;
    //     border: 1px solid $text-015-color;
    //     border-radius: 2px;
    //   }
    //   .defaultBtn:hover{
    //     border: 1px solid $blue-new-color;
    //     border-radius: 2px;
    //     color: $blue-085-color;
    //   }
    // }
  }

  .manageContent{
    .contentTop{
      width: 100%;
      padding: 24px;
      .contentItem{
          display: flex;
          height: 32px;
          line-height: 32px;
          position: relative;
         .itemTitle{
           display: inline-block;
           width: 98px;
           font-size: 14px;
           color: $text-085-color;
           text-align: right;
           white-space: nowrap;
         }
         input{
            width: 650px;
            background: rgba(255,255,255,1);
            border: 1px solid $text-015-color;
            border-radius: 4px;
            text-indent: 12px;
            font-size: 14px;
            color:$text-085-color;
            &:hover{
              border-color: $new-base-color;
            }
           &.error{
             border: 1px solid #E00224;
           }
          }
        input::placeholder{
          color:$text-025-color;
        }
         .itemTips{
          position: absolute;
          top: 0;
          right:12px;
          font-size: 14px;
          color: $text-025-color;
        }

      }
      .mrt{
        margin-top: 20px;
      }
      .errorMessage{
        position: absolute;
        top: 30px;
        margin-bottom: 4px;
        margin-left: 98px;
        font-size: 12px;
        color: #E00224;
      }
    }
    .contentBottom{
      background: $background-100-color;
      min-height: 250px;
      padding: 24px;
      .bottomTitle{
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        color: $text-045-color;
        margin-bottom: 16px;
      }

      .bottomAdd{
        width: 130px;
        height: 22px;
        line-height: 22px;
        font-size: 14px;
        font-weight: normal;
        cursor: pointer;
        color: $blue-new-color;
        &:hover{
          color: $blue-new-hover-color;
        }
        &.disable{
          color: $text-025-color;
          cursor: not-allowed;
        }
        em{
          font-size: 20px;
        }
      }
    }
  }
}

/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.cartNew {
  position: relative;
  .goods {
    margin-top: 16px;
    .head {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background: #f7f7f8;
      ul {
        display: flex;
        li {
          font-size: 14px;
          color: rgba(0, 3, 20, 0.45);
          text-align: center;
          &:nth-child(1) {
            width: 80px;
            text-align: left;
            text-indent: 16px;
            span {
              display: inline-block;
              margin-left: 4px;
            }
          }
          &:nth-child(2) {
            text-align: left;
            width: 390px;
          }
          &:nth-child(3) {
            width: 150px;
            text-align: left;
          }
          &:nth-child(4) {
            width: 110px;
            text-align: center;
          }
          &:nth-child(5) {
            text-align: center;
            padding-left: 40px;
            width: 180px;
          }
          &:nth-child(6) {
            width: 190px;
            padding-right: 40px;
            text-align: right;
          }
          &:nth-child(7) {
            text-align: left;
            width: 130px;
          }
        }
      }
    }
    .content {
      width: 100%;
      .rowContainter {
        width: 100%;
        padding: 20px 24px;
        background-color: #fff;
        border-radius: 4px;
        margin-bottom: 12px;
        border: 1px solid #fff;
      }
      .materialCodeContainer {
        border-top: 1px solid rgba(7, 14, 50, 0.05);
        margin-top: 10px;
        margin-left: 136px;
        padding-top: 10px;
        display: flex;
      }
      .row {
        width: 100%;
        display: flex;
        font-size: 14px;
        align-items: flex-start;
        .check {
          width: 54px;
          // padding-left: 16px;
          input {
            cursor: pointer;
          }
          i {
            display: block;
            width: 14px;
            height: 14px;
            border: 1px solid rgba(0, 3, 20, 0.05);
            border-radius: 2px;
            cursor: not-allowed;
          }
        }
        .good {
          width: calc(40% - 54px);
          .main {
            display: flex;
            .skuImg {
              width: 72px;
              height: 72px;
              border: 1px solid #dfe1e4;
              border-radius: 4px;
              margin-right: 12px;
              display: inline-block;
              position: relative;
              cursor: pointer;
              overflow: hidden;
              .stock {
                position: absolute;
                background: rgba(0, 3, 20, 0.6);
                top: 50%;
                margin-top: -30px;
                width: 60px;
                height: 60px;
                display: inline-block;
                border-radius: 50%;
                left: 5px;
                color: #fff;
                font-size: 12px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                  color: #fff;
                  font-size: 12px;
                  display: inline-block;
                  width: 54px;
                  height: 54px;
                  line-height: 54px;
                  border-radius: 50%;
                  border: 1px dashed rgba(225, 225, 225, 0.4);
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  em {
                    display: inline-block;
                    color: #fff;
                    line-height: 16px;
                    width: 40px;
                    font-weight: 500;
                  }
                }
              }

              .skuImgBox {
                width: 70px;
                height: 70px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
              }
            }

            .detail {
              padding-right: 20px;
              flex: 1;
              .dess {
                display: flex;
                flex-direction: column;
                > div {
                  margin-top: 6px;
                }
              }
              .title {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3; //多行在这里修改数字即可
                -webkit-box-orient: vertical;
                color: #00678b;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0px;
                margin-bottom: 6px;
              }
              .code,
              .unit {
                line-height: 22px;
                color: rgba(0, 3, 20, 0.45);
              }
            }
          }
          .gift {
            margin-bottom: 10px;
          }
        }
        .mrt10 {
          margin-top: 10px;
        }
        .specification {
          cursor: default;
          word-break: break-all;
          width: 150px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1; //多行在这里修改数字即可
          -webkit-box-orient: vertical;
        }
        .price {
          width: 12%;
          text-align: left;
        }
        .moqtip {
          font-size: 12px;
          color: #ff8b1b;
          line-height: 20px;
          margin-top: 2px;
          text-align: center;
        }
        .amount {
          width: 20%;
          position: relative;
          padding: 0 20px;
          display: flex;
          justify-content: center;
          .priceInput {
            width: 100%;
            max-width: 140px;
          }
          .minimum {
            position: absolute;
            top: 40px;
            width: 180px;
            font-size: 12px;
            color: #ff7c00;
          }
        }
        .redmount {
          position: relative;
          input {
            color: red;
          }
          .minimum {
            position: absolute;
            top: 40px;
            width: 180px;
            font-size: 12px;
            color: #ff7c00;
          }
        }
        .total {
          width: 14%;
          text-align: center;
        }
        .operate {
          width: 14%;
          text-align: right;
          cursor: pointer;
          font-size: 12px;
          line-height: 22px;
          color: #070e32;
          display: flex;
          flex-direction: column;
          .operateBtn {
            margin-bottom: 12px;
          }
        }
      }
      .disableRow {
        color: rgba(0, 0, 0, 0.25);
        .good {
          margin-top: 10px;
          .main {
            .detail {
              .title {
                a {
                  color: rgba(0, 0, 0, 0.25);
                }
                a:hover {
                  color: $blue-color-hover;
                }
              }
              .code,
              .unit {
                color: rgba(0, 0, 0, 0.25);
              }
            }
          }
          .disableProduct {
            margin-top: 4px;
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: normal;
            color: #ff7c00;
          }
        }
        .total {
          color: rgba(0, 0, 0, 0.25);
        }
        .operate {
          color: rgba(0, 3, 20, 0.85);
          &:hover {
            color: $red-color;
          }
        }
      }
      .active {
        border-color: rgba(0, 103, 139, 0.25);
        background: linear-gradient(0deg, rgba(0, 103, 139, 0.03), rgba(0, 103, 139, 0.03)),
          linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
      }
    }
  }

  //编辑物料编码
  .editMaterialCode {
    display: flex;
    width: 30%;
    .editMaterialCodeInfo {
      width: 100%;
    }
  }
  .editMaterialBtns {
    display: flex;
    align-items:  flex-end;
    margin-bottom: 13px;
    :global {
      .ant-btn {
        margin-right: 7px;
      }
    }
  }
  .modifyBtn {
    color: #070e32;
    font-size: 12px;
    text-align: right;
    margin-top: 3px;
    flex: 1;
    .modifyBtnEdit {
      cursor: pointer;
    }
  }
  .materialCodeTxt {
    font-size: 14px;
    color: rgba(0, 3, 20, 0.45);
    margin-right: 20px;
  }
  .placeOrder {
    position: relative;
    height: 72px;
    background: #ffffff;
    box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.08);
    display: flex;
    border-radius: 4px;
    margin-bottom: 50px;
    .left {
      width: 220px;
      display: flex;
      align-items: center;
      padding-left: 24px;
      .selectAll {
        color: rgba(0, 3, 20, 0.85);
        input {
          margin-right: 4px;
          cursor: pointer;
        }
      }
      .deleteAll {
        color: rgba(0, 3, 20, 0.85);
        cursor: pointer;
        font-size: 14px;
      }
    }
    .center {
      flex: 1;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .pieces {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        color: rgba(0, 3, 20, 0.45);
      }
      .price {
        height: 24px;
        line-height: 24px;
        .title {
          color: rgba(0, 3, 20, 0.85);
          font-size: 16px;
        }
        .num {
          margin-left: 4px;
          em {
            font-style: normal;
            font-size: 18px;
          }
          font-size: 24px;
          font-weight: 500;
          color: #e00224;
        }
      }
    }
    .right {
      display: flex;
      width: 180px;
      align-items: center;
      justify-content: center;
    }
    .slideProducts {
      height: 88px;
      border: 1px solid rgba(0, 3, 20, 0.05);
      border-radius: 2px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      background: #ffffff;

      position: absolute;
      top: -88px;
      right: 140px;
      z-index: 9;
      .pre,
      .next {
        position: absolute;
        top: 28px;
        width: 24px;
        height: 32px;
        background: rgba(0, 3, 20, 0.25);
        z-index: 9;
        cursor: pointer;
        .outlined {
          width: 8px;
          height: 16px;
          color: #fff;
          margin-left: 4px;
          margin-top: 8px;
        }
        &:hover {
          background: rgba(0, 3, 20, 0.5);
        }
      }
      .pre {
        border-radius: 0 100px 100px 0;
      }
      .next {
        right: 0;
        border-radius: 100px 0 0 100px;
      }
      .products {
        width: 100%;
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        display: flex;
        padding-right: 8px;
        .product {
          position: relative;
          img {
            width: 72px;
            height: 72px;
            border: 1px solid rgba(0, 3, 20, 0.15);
            margin-top: 8px;
            margin-left: 8px;
          }
          span {
            display: block;
            width: 72px;
            height: 20px;
            line-height: 20px;
            position: absolute;
            bottom: 2px;
            left: 8px;
            background: rgba(0, 3, 20, 0.45);
            font-size: 10px;
            color: #ffffff;
            text-align: center;
          }
        }
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
      }
    }
    .flexWidth {
      width: 890px;
    }
    .minWidth {
      min-width: 88px;
    }
  }
  .fixedOrder {
    position: fixed;
    bottom: 0;
  }
  .empty {
    width: 100%;
    height: calc(100vh - 184px);
    background: #ffffff;
    .content {
      width: 303px;
      height: 244px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      .logo {
        width: 152px;
        height: 152px;
        margin: 0 auto;
        background: url('../../assets/images/common/icon_cart_empty.svg') no-repeat center;
      }
      .tips {
        height: 44px;
        line-height: 44px;
        font-size: 18px;
        color: #666666;
      }
    }
  }
  :global {
    .ant-tooltip-content.ant-tooltip-inner {
      color: red !important;
    }
  }
}
.deleteModal {
  :global {
    .ant-modal-body {
      //padding: 0 !important;
      margin-top: 17px;
    }
    .ant-modal-footer {
      border-top: none !important;
    }
  }
}

.modalDelete {
  padding: 8px 0;
  max-height: 464px;
  overflow-y: scroll;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  .product {
    display: flex;
    background: #f5f6fa;
    padding: 16px 24px;
    img {
      width: 66px;
      height: 66px;
      border: 1px solid #DFE1E4;
      border-radius: 4px;
    }
    .detail {
      margin-left: 14px;
      .title {
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; //多行在这里修改数字即可
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
        font-weight: 500;
        color: rgba(0, 3, 20, 0.85);
      }
      .code,
      .unit {
        line-height: 22px;
        color: rgba(0, 3, 20, 0.45);
      }
    }
  }
}

/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.img-zoom-preview {
  width: 100%;

  .imgContainer {
    padding: 14px 0;
  }
  .main-img {
    position: relative;
    padding-top: 100%;
    // height: 228px;
    // width: 100%;
    .grey {
      opacity: 0.5;
    }
    img {
      cursor: pointer;
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 100%;
      max-height: 100%;
    }
    .box_bg {
      position: absolute;
      bottom: 0;
      height: 40px;
      width: 100%;
      left: 1px;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.02) 100%);
    }
    .parameter_box {
      width: 240px;
      height: 230px;
      cursor: pointer;
      // display: none;
      padding: 33px 6px 0 6px;
      .param_cont {
        width: 230px;
        min-height: 28px;
        border: 1px solid rgba(240, 240, 240, 1);
        margin-top: 3px;
        display: flex;
        .param_key {
          text-align: center;
          width: 64px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(243, 243, 243, 1);
          font-size: 12px;
          height: 28px;
          font-weight: normal;
          color: rgba(153, 153, 153, 1);
        }
        .param_val {
          width: 150px;
          height: 28px;
          text-align: left;
          font-size: 12px;
          display: flex;
          justify-content: left;
          align-items: center;
          align-content: center;
          font-weight: normal;
          padding-left: 7px;
          color: rgba(102, 102, 102, 1);
          padding: 8px;
          line-height: 18px;
        }
      }
    }
    .parameter {
      z-index: 1;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 32px;
      height: 20px;
      line-height: 20px;
      background: rgba(13, 71, 160, 0.45);
      color: #fff;
      text-align: center;
      cursor: pointer;
      &:hover {
        background: #00678B;
      }
    }
    .parameter:hover + .parameter_box {
      display: block;
      width: 240px;
      height: 228px;
      background: #fff;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .list-img-wrap {
    height: 38px;
    overflow: hidden;
    position: relative;
    .prev,
    .next,
    .list {
      float: left;
    }
    .list {
      width: 32px;
      height: 32px;
      margin: 0 5px;
      overflow: hidden;
      box-sizing: border-box;
      border: 1px solid #ededed;
      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      &:hover {
        border: 1px solid #00678B;
      }

      &:first-of-type {
        margin-left: 0;
      }
    }

    .cur {
      border: 1px solid #00678B;
      border-radius: 2px;
    }

    ul {
      width: 268px; //452 324
      height: 58px;
      overflow: hidden;

      li {
        float: left;
        height: 52px;
        width: 52px;
        border: 1px solid #eee;
        box-sizing: content-box;
        margin: 1px 12px 0;

        &.active,
        img {
          height: 100%;
          width: 100%;
        }
      }

      .cur {
        margin: 0 11px;
        border: 1px solid #ff7c00;
      }
    }
  }
  .swpierContenter {
    position: absolute;
    top: 0;
    left: 0;
    height: 38px;
    overflow: hidden;
    width: 100%;
    .swiperSilder {
      position: absolute;
      top: 0;
      transition: left 0.2s linear;
    }
  }
}

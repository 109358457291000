/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

@import '@/assets/style/config';

.red {
  color: $red-color;
}

.blue {
  color: $blue-color;
}

.bold {
  font-weight: bold;
}

.buyAccountModal {
  :global {
    .ant-input-affix-wrapper {
      height: 40px;
    }
  }
}
.content{
  padding: 0 16px 16px 6px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  flex: 1;
  
  min-height: calc(100vh - 184px);
  
  .orderList{
    flex: 1;
    overflow-y: auto;
    :global {
      .ant-btn[disabled]{
        background: #fff;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .checked{
    font-size: 14px;
    color: rgba(0,0,0,0.85);
    margin: 0 32px;
  }
  .empty{
    text-align: center;
    margin-top: 64px;
    img{
      width: 120px;
    }
    div{
      &.title{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 600;
        color: rgba(0,0,0,0.85);
        margin-top: 16px;
      }
      &.tips{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: normal;
        color: rgba(0,0,0,0.85);
        margin-top: 4px;
      }
      &.button{
        width: 156px;
        height: 48px;
        // background: rgba(25,56,237,1);
        // border-radius: 2px;
        // line-height: 48px;
        // font-size: 18px;
        // font-family: PingFang SC;
        // font-weight: 500;
        // color: rgba(255,255,255,1);
        // margin: 24px auto;
        // cursor: pointer;
      }
    }
  }
  :global {
    // .ant-btn-primary{
    //   height: 32px;
    //   background: rgba(25,56,237,1);
    //   border-radius: 2px;
    //   font-size: 14px;
    //   font-family: PingFang SC;
    //   font-weight: normal;
    //   color: rgba(255,255,255,1);
    // }
    // .ant-btn-default{
    //   width: 88px;
    //   height: 32px;
    //   border: 1px solid rgba(0,3,20,0.15);
    //   border-radius: 2px;
    //   background: #fff;
    //   font-size: 14px;
    //   font-family: PingFang SC;
    //   font-weight: normal;
    //   color: rgba(0,3,20,0.85);
    // }
    .ant-input-group-wrapper{
      width: 220px;
    }
    .ant-input-wrapper {
      .ant-input{
        width: 220px;
      }
      .ant-input:hover, .ant-input:focus{
        border-color: #00678B!important;
      }
      .ant-input-group-addon{
        background: none;
        z-index: 2;
        left: -35px!important;
      }
      .ant-btn-default{
        width: 35px;
        border: none!important;
        background: none!important;
      }
    }
  }

}
.checkAll {
  :global {
    .ant-checkbox-inner {
      background-color: $blue-color;
      border: 1px solid #fff;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #fff !important;
      border-color: #fff !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      border: 2px solid $blue-color;
      border-top: 0;
      border-left: 0;
      top: 42% !important;
      left: 18% !important;
      transition: none;
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner {
      background-color: #fff !important;
      border-color: #fff !important;
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      top: 20% !important;
      left: -12% !important;
      background-color: transparent !important;
      width: 5.71428571px;
      height: 9.14285714px;
      border: 2px solid $blue-color;
      border-top: 0;
      border-left: 0;
      border-bottom: 0;
      transform: rotate(90deg) scale(1) translate(-50%, -50%);
      transition: none;
    }
  }
}

.batchActions {
  display: flex;
  justify-content: space-between;
  :global {
    .ant-btn:not([disabled]) {
      &:hover {
        color: $blue-color;
        border-color: $blue-color;
        background-color: #fff;
      }
    }
  }
}

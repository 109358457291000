/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

// @import '@/assets/style/config';
.settleConfig{
  width: calc(100% - 198px);
  .head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    width: 100%;
    border-bottom: 1px solid #DFE1E4;
    margin-bottom: 16px;

    .title{
      font-weight: 500;
      font-size: 18px;
    }
  }
  .table{
    :global {
      .ant-table {
        .ant-table-content {
          border-bottom: 0;
        }
        .ant-table-cell {
          font-size: 14px;
          vertical-align: middle;
          white-space: normal;
          height:22px;
        }
        .ant-table-expand-icon-col {
          width: 16px;
        }
  
        .ant-table-row-expand-icon-cell {
          .ant-table-row-expand-icon {
            display: none;
          }
        }
        .ant-table-thead {
          .ant-table-cell {
            padding: 0;
            line-height: 36px;
            text-align: center;
            font-size: 14px;
            color: #595B66;
            background: #F2F3F5;
            border-top: 1px solid #DFE1E4;
            border-bottom: 1px solid #DFE1E4;
            &::before {
              display: none;
            }
          }
          .ant-table-cell:first-child{
            border-left: 1px solid #DFE1E4;
          }
          .ant-table-cell:last-child{
            border-right: 1px solid #DFE1E4;
          }
        }
        .ant-table-tbody {
          .ant-table-cell {
            vertical-align: top;
              &:nth-child(1) {
                border-left: 0;
              }
              &:last-child {
                border-right: 0;
              }
          }
          tr{
            &:last-child{
              td{
                border-bottom: 1px solid #f0f0f0 !important;
              }
            }
          }
          .ant-table-expanded-row{
            .ant-table-cell{
              padding: 0;
            }
          }
        }
        tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td{
          background-color: #fff !important;
        }
      }

    }
    .enumeration{
      width: 480px;
      flex: 1;
      display: inline;
      text-align: left;
      height: 40px;
      line-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .all{
      width: 74px;
      cursor: pointer;
      height: 40px;
      line-height: 40px;
      float: right;
      display: inline;
      font-size:14px;
      color:$new-base-color;
      &:hover{
        // color: $new-base-hover-color;
      }
    }
  }
  .enumRow{
    position: relative;
    height: 40px;
    line-height: 40px;
    margin-left:20px;
    margin-right: 20px;
    li{
      width: 406px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      float: left;
    }
  }
  .editCategory{
    display: flex;
    justify-content: left;
    align-items: center;
    .input{
      margin-right: 10px;
    }
  }

  .operations{
    //position: absolute;
    //right: 28px;
    //top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
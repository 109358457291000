@font-face {
  font-family: 'RelayIcon';
  src: url('//storage.jd.com/ic-ux/isp/iconfont/RelayIcon.eot'); /* IE9 */
  src: url('//storage.jd.com/ic-ux/isp/iconfont/RelayIcon.eot#iefix') format('embedded-opentype') /* IE6-IE8 */,
    url('//storage.jd.com/ic-ux/isp/iconfont/RelayIcon.woff2') format('woff2') /* chrome、firefox */,
    url('//storage.jd.com/ic-ux/isp/iconfont/RelayIcon.woff') format('woff') /* chrome、firefox */,
    url('//storage.jd.com/ic-ux/isp/iconfont/RelayIcon.ttf') format('truetype')
      /* chrome、firefox、opera、Safari, Android, iOS 4.2+ */,
    url('//storage.jd.com/ic-ux/isp/iconfont/RelayIcon.svg#RelayIcon') format('svg') /* iOS 4.1- */;
}

.iconFont {
  font-family: 'RelayIcon'!important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-navbar_sort::before {
  content: '\eb0f';
}

.icon-cancel-n::before {
  content: '\eae2';
}

.icon-toolbar_delete::before {
  content: '\f277';
}

.icon-arrow_expandleft::before {
  content: '\ed71';
}

.icon-platform_countdown::before {
  content: '\eb64';
}

.icon-navbar_chosen::before {
  content: '\e0ae';
}

.icon-close::before {
  content: '\f2c4';
}

.icon-handoff_left::before {
  content: '\f0aa';
}

.icon-xunyuan::before {
  content: '\f2ff';
}

.icon-cai::before {
  content: '\e3d6';
}

.icon-tc::before {
  content: '\ea8c';
}

.icon-shoppingcart::before {
  content: '\e2b4';
}

.icon-downarrow::before {
  content: '\e504';
}

.icon-shouhuo1::before {
  content: '\e430';
}

.icon-aftersale::before {
  content: '\e34e';
}

.icon-jxsp1::before {
  content: '\ea6b';
}

.icon-navbar_avatar::before {
  content: '\e824';
}

.icon-navbar_search::before {
  content: '\e021';
}

.icon-down_arrow::before {
  content: '\e7be';
}

.icon-price-down::before {
  content: '\e36e';
}

.icon-qbsp2::before {
  content: '\e3e9';
}

.icon-navbar_fire::before {
  content: '\e706';
}

.icon-plrx1::before {
  content: '\ecfa';
}

.icon-spjd::before {
  content: '\f4b9';
}

.icon-zjtj2::before {
  content: '\e986';
}

.icon-list_bigpicture::before {
  content: '\ea59';
}

.icon-eyes1::before {
  content: '\f50d';
}

.icon-plrx2::before {
  content: '\f3ae';
}

.icon-finish::before {
  content: '\e827';
}

.icon-uparrow::before {
  content: '\e281';
}

.icon-arrow_expand::before {
  content: '\ed36';
}

.icon-remind1::before {
  content: '\ed3d';
}

.icon-aftersales::before {
  content: '\e95f';
}

.icon-navbar::before {
  content: '\f099';
}

.icon-platform_shop::before {
  content: '\ea43';
}

.icon-share::before {
  content: '\e2ce';
}

.icon-system_platform_countdown::before {
  content: '\e719';
}

.icon-navbar_new::before {
  content: '\e7fa';
}

.icon-dongdong::before {
  content: '\eef4';
}

.icon-navbar_cancel::before {
  content: '\e0bb';
}

.icon-navbar_workbench::before {
  content: '\e3c8';
}

.icon-navbar_subtract::before {
  content: '\f215';
}

.icon-sent::before {
  content: '\e85f';
}

.icon-zjtj1::before {
  content: '\f022';
}

.icon-thdzm1::before {
  content: '\e89d';
}

.icon-navbar_app::before {
  content: '\e5c1';
}

.icon-bjx-pldc::before {
  content: '\e367';
}

.icon-navbar_prompt::before {
  content: '\e9e3';
}

.icon-toolbar_print::before {
  content: '\e6fa';
}

.icon-cancelorder::before {
  content: '\e379';
}

.icon-navbar_notice::before {
  content: '\f44b';
}

.icon-thdzm2::before {
  content: '\ed18';
}

.icon-sign::before {
  content: '\f282';
}

.icon-navbar_preview::before {
  content: '\edb3';
}

.icon-dingwei::before {
  content: '\eaed';
}

.icon-navbar_forward::before {
  content: '\f2c1';
}

.icon-merchantreview::before {
  content: '\f0bf';
}

.icon-navbar_upload::before {
  content: '\e8b8';
}

.icon-navbar_doubt::before {
  content: '\e94a';
}

.icon-jxsp2::before {
  content: '\e895';
}

.icon-navbar_prompt_area::before {
  content: '\e30c';
}

.icon-platform_calendar::before {
  content: '\e606';
}

.icon-daifahuo::before {
  content: '\ef8b';
}

.icon-navbar_after::before {
  content: '\ede9';
}

.icon-navbar_right::before {
  content: '\e392';
}

.icon-handoff_right::before {
  content: '\ed76';
}

.icon-navbar_more::before {
  content: '\e016';
}

.icon-navbar_mistake::before {
  content: '\e4c3';
}

.icon-feedback::before {
  content: '\e0b3';
}

.icon-back_arrow::before {
  content: '\e7f3';
}

.icon-round_add::before {
  content: '\ef86';
}

.icon-navbar_mail::before {
  content: '\e65f';
}

.icon-navbar_left::before {
  content: '\f1da';
}

.icon-navbar_icon_not::before {
  content: '\ede5';
}

.icon-close2::before {
  content: '\ef57';
}

.icon-navbar_password::before {
  content: '\e544';
}

.icon-select_icon::before {
  content: '\f2f6';
}

.icon-navbar_trace::before {
  content: '\f1c6';
}

.icon-navbar_succeed::before {
  content: '\ec3c';
}

.icon-navbar_customer::before {
  content: '\e7ca';
}

.icon-platform_close::before {
  content: '\ec3f';
}

.icon-navbar_back::before {
  content: '\e842';
}

.icon-navbar_warn::before {
  content: '\eade';
}

.icon-arrow_collapse::before {
  content: '\ef91';
}

.icon-navbar_account::before {
  content: '\f045';
}

.icon-payment::before {
  content: '\eecf';
}

.icon-navbar_arrowdown::before {
  content: '\edfb';
}

.icon-navbar_fire1::before {
  content: '\e4e6';
}

.icon-doublearrow-d::before {
  content: '\e0e4';
}

.icon-newchat::before {
  content: '\eaf4';
}

.icon-price-rise::before {
  content: '\e2cb';
}

.icon-qxrc::before {
  content: '\e6a5';
}

.icon-navbar_classify::before {
  content: '\ed70';
}

.icon-upload::before {
  content: '\e5e7';
}

.icon-scanning::before {
  content: '\e4eb';
}

.icon-arrowright::before {
  content: '\eaeb';
}

.icon-wdcg1::before {
  content: '\e7ff';
}

.icon-toolbar_location::before {
  content: '\efb3';
}

.icon-weitu::before {
  content: '\e166';
}

.icon-navbar_download::before {
  content: '\e19b';
}

.icon-arrow_expandright::before {
  content: '\f321';
}

.icon-merchantright::before {
  content: '\e8be';
}

.icon-zan::before {
  content: '\e8cc';
}

.icon-shop::before {
  content: '\e124';
}

.icon-rmb_icon::before {
  content: '\e6c0';
}

.icon-navbar_magnify::before {
  content: '\f207';
}

.icon-open::before {
  content: '\f152';
}

.icon-note_rmb::before {
  content: '\e728';
}

.icon-navbar_feedback::before {
  content: '\e579';
}

.icon-sptj::before {
  content: '\e987';
}

.icon-qbsp1::before {
  content: '\ea5c';
}

.icon-7days::before {
  content: '\eaee';
}

.icon-navbar_label_close::before {
  content: '\e231';
}

.icon-navbar_invoice::before {
  content: '\e531';
}

.icon-narbar_figure::before {
  content: '\e353';
}

.icon-addtolist::before {
  content: '\e684';
}

.icon-doublearrow-u::before {
  content: '\f286';
}

.icon-close1::before {
  content: '\ecd0';
}

.icon-arrowleft::before {
  content: '\e1fe';
}

.icon-wdcg2::before {
  content: '\f0d1';
}

.icon-navbar_information::before {
  content: '\ef20';
}

.icon-navbar_add::before {
  content: '\e2e9';
}

.icon-icon::before {
  content: '\e64a';
}

.icon-navbar_hot::before {
  content: '\f0bb';
}

.icon-navbar_batch::before {
  content: '\ec44';
}

.icon-navbar_star::before {
  content: '\f289';
}

.icon-add::before {
  content: '\e75d';
}

.icon-navbar_arrow::before {
  content: '\e626';
}

.icon-navbar_time::before {
  content: '\e662';
}

.icon-navbar_load::before {
  content: '\eecc';
}

.icon-toolbar_install::before {
  content: '\e8ee';
}

.icon-navbar_expand::before {
  content: '\e475';
}

.icon-navbar_flushed::before {
  content: '\e96f';
}

.icon-bill::before {
  content: '\e84a';
}

.icon-camera::before {
  content: '\e0d9';
}

.icon-navbar_hide::before {
  content: '\e31a';
}

/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.drawer_style{
  :global{
    .ant-drawer-header{
      color: rgba(0,0,0,0.85);
      padding: 16px 24px 16px 12px !important;
    }
    .ant-drawer-body{
      padding: 0 !important;
    }
  }
  .drawer_close{
    display: inline-block;
    width: 12px;
    height: 12px;
    cursor: pointer;
    background: url("../../assets/images/orderList/icon_close.svg") no-repeat;
  }
}
.approve {
  margin-bottom: 10px;
  background-color: #fff;
  padding: 15px 20px;

  .approveTitle {
    display: flex;
    line-height: 22px;
    justify-content: space-between;

    .titleLeft {
      display: block;
      color: #262626;
      font-size: 14px;
      font-weight: bold;
    }

    .titleRight {
      color: $a-color;
      cursor: pointer;
    }
  }

  .approveDetail {
    width: 100%;
    padding-top: 23px;
    display: flex;

    .left {
      width: 280px;
      height: 154px;
      border-right: 1px solid rgba(0, 0, 0, 0.08);
      box-sizing: border-box;
      text-align: center;

      .state {
        display: inline-block;
        padding-left: 34px;
        height: 30px;
        font-size: 20px;
        font-weight: $semibold;
        color: $red-color;
        background: url(../../assets/images/order_detail/notification-fill@2x.png) no-repeat center left;
        background-size: 24px;
        line-height: 30px;
        margin-bottom: 10px;
      }

      .boxs {
        width: 200px;
        height: 80px;
        background: #f7f8fc;
        border-radius: 4px;
        margin: 10px auto 0;
        padding: 15px 19px 0;
        box-sizing: border-box;
        text-align: left;

        .name {
          display: block;
          color: $text-3-color;
          font-size: 12px;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          i {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            background: #e2e8f4 url(../../assets/images/order_detail/user-fill@2x.png) no-repeat center;
            background-size: 12px;
            vertical-align: middle;
            margin-right: 10px;
          }
        }

        .file {
          cursor: pointer;
          display: block;
          padding: 10px 0 0 0;
          color: $a-color;
          font-size: 12px;
          line-height: 20px;

          i {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            background: #e2e8f4 url(../../assets/images/order_detail/edit@2x.png) no-repeat center;
            background-size: 12px;
            vertical-align: middle;
            margin-right: 10px;
          }
        }
      }
    }

    .right {
      width: 580px;
      padding: 0 60px 0 40px;
    }
  }
}

.approvalModal{
  margin-left: 12px;
  clear: both;
  .title{
    font-size:14px;
    font-weight: bold;
    padding:20px 0 ;
  }
  .approvalList {
    width: 100%;
    margin: 0 auto;

    li {
      display: flex;
      margin: 4px 0 0;

      &.hide {
        display: none;
      }

      .state {
        display: block;
        width: 60px;
        text-align: right;
        font-size: 14px;
        font-weight: 500;
        margin: 0 20px 0 0;
        line-height: 24px;
        color: #ff7c00;
      }

      &.suc {
        .detail {
          .nodeIcon {
            .icon {
              background: #fff url(../../assets/images/order_detail/checkbox-circle-fill@2x.png) no-repeat;
              background-size: 20px;
            }
            .b {
              background: linear-gradient(360deg, rgba(67,220,146,0) 0%,rgba(32,183,89,1) 100%);
              border-radius: 2px;
            }
          }
        }

        .state {
          color: #3b4;
        }
      }

      &.rej {
        .state {
          color: $red-color;
        }

        .detail {
          .nodeIcon {
            .icon {
              background: #fff url(../../assets/images/order_detail/checkbox-rej-fill@2x.png) no-repeat;
              background-size: 20px;
            }
            .b {
              background: linear-gradient(360deg, rgba(254,247,247,1) 0%,rgba(253,107,109,1) 100%);
              border-radius: 2px;
            }
          }
        }
      }

      .detail {
        flex: 1;
        display: flex;
        overflow: hidden;

        .nodeIcon {
          display: block;
          min-height: 44px;
          position: relative;

          .icon {
            display: block;
            width: 20px;
            height: 20px;
            margin: 2px 20px 2px 0;
            background: #fff url(../../assets/images/order_detail/time-line@2x.png) no-repeat;
            background-size: 20px;
          }

          .b {
            position: absolute;
            width: 2px;
            height: calc(100% - 10px);
            background: rgba(0, 0, 0, 0.09);
            top: 26px;
            left: 9px;
          }

          .bg_opacity {
            position: absolute;
            display: inline-block;
            width: 20px;
            height: calc(100% - 10px);
            top: 26px;
            background: url(../../assets/images/order_detail/bg@2x.png) repeat;
          }
        }

        .boxs {
          flex: 1;
          .top {
            p {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              em{
                font-style: normal;
              }
              .level {
                width: 150px;
                display: block;
                color: rgba(0,0,0,0.85);
                font-size: 14px;
                // line-height: 24px;
                padding-right: 10px;
                text-align: left;
              }
              .name {
                display: block;
                color: $text-3-color;
                font-size: 14px;
                text-align: left;
                margin-right: 16px;
              }
              .date {
                width: 200px;
                display: block;
                color: #999999;
                font-size: 12px;
                margin-top: 1px;
                text-align: left;
              }
            }
          }
        }
      }

      .suggest {
        margin: 10px 0 0;
        width: 80%;
        height: 38px;
        line-height: 18px;
        padding: 10px;
        box-sizing: border-box;
        background: #f7f8fc;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $text-3-color;
        font-size: 12px;
      }

      &:last-child {
        .nodeIcon {
          min-height: 24px;
        }
      }
    }
  }
}

//审批文件
.fileModel {

  position: relative;
  overflow: hidden;
  padding-bottom: 10px;
  .top {
    width: 100%;
    min-height: 50px;
    background: rgba(25,56,237,0.04);
    box-sizing: border-box;
    color: $text-6-color;
    line-height: 22px;
    position: relative;
    overflow: hidden;
    padding: 12px 16px;
    .row {
      padding: 0 0 15px;
      width: 25%;
      float: left;
      font-size:12px;
      text-align: left;
      .text {
        color:#999999;
      }
      .val {
        color: rgba(0,0,0,0.85);
      }
    }
  }
  .bottom {
    margin-top: 9px;
    margin-left: 12px;
    .left {
      color: rgba(0,0,0,0.85);
      font-size: 14px;
      line-height: 32px;
      font-weight: bold;
      padding-bottom: 16px;
    }
    .right {
      width: 540px;
      margin-right: 20px;
      height: 240px;
      display: flex;
      border: 1px solid rgba(225,225,225,1);
      justify-content: center;
      align-items: center;
      :global{
        .ant-upload{
          display: block;
        }
      }
      .content{
        width: 240px;
        height: 100%;
        text-align: center;
        margin-left: 16px;
        .uploadBtn {
          padding: 7px 15px 7px 36px;
          background: url(../../assets/images/order_detail/upload-line.svg) no-repeat 15px center;
          background-size: 16px;
          line-height: 18px;
          border: 1px solid rgba(0,0,0,0.25);
          border-radius: 2px;
          color: rgba(0,0,0,0.85);
          font-size: 12px;
          margin: 28px 0 16px;
          cursor: pointer;
        }
        .warnBox {
          width: 100%;
          color:#999999;
          font-size:12px;
          overflow: hidden;
          text-align: left;
          line-height: 20px;
        }
      }

    }
  }

}

.remind {
  display: inline-block;
  cursor: pointer;
  width: 78px;
  height: 24px;
  background: rgba(255, 255, 255, 0.85);
  border: 1px solid rgba(13, 71, 160, 1);
  border-radius: 2px;
  color: $blue-color;
  font-size: 12px;
  text-align: center;
  line-height: 22px;

  &:hover {
    background: #205fc7;
    color: #fff;
    border-color: #205fc7;
  }

  &.s {
    margin-top: 10px;
  }
}

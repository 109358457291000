/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

@import '@/assets/style/config';

.productFullBox {
  margin-bottom: 18px;
  border: 1px solid rgba(7, 14, 50, 0.1);
  border-radius: 4px;
  &.productFullActive {
    border-color: #bdd8e1;
    background: rgba(0, 103, 139, 0.03);
    .productFullMulBoxActive {
      border-color: transparent;
    }
  }
  .productFullMulBox {
    border: 1px solid transparent;
    border-top: 1px solid #e3e3e7;
  }
  .productFullMulBoxActive {
    border: 1px solid #bdd8e1;
    background: rgba(0, 103, 139, 0.03);
    border-radius: 4px;
    overflow: hidden;
    .headerRow {
      background: none !important;
    }
  }
}

.moreBtn {
  padding: 13px;
  font-size: 12px;
  border-top: 1px solid #e6e7ea;
  text-align: center;
  border-radius: 0px 0px 4px 4px;
  color: #070e32;
  cursor: pointer;
}

.productItemDetails {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  border-right: 1px solid rgba(7, 14, 50, 0.05);
  padding: 16px 24px 16px 24px;
  border-bottom-left-radius: 4px;
  &:nth-of-type(n + 2) {
    border-top: 1px solid rgba(7, 14, 50, 0.05);
  }
  .productItemquantity {
    flex-shrink: 0;
    font-size: 14px;
    line-height: 1.14;
    color: #070e32;
    text-align: right;
    width: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:  flex-end;
    .shouhouBtn {
      color: #00678b;
      font-size: 14px;
      text-align: right;
      cursor: pointer;
    }
  }
  .productItemInfoBox {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .productItemImage {
      align-self: flex-start;
      width: 72px;
      height: 72px;
      border: 1px solid #dfe1e4;
      border-radius: 2px;
    }
    .productItemInfo {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      margin-left: 12px;
      min-width: 0;
      flex: 1;
    }
    .productItemName {
      flex-shrink: 0;
      font-size: 14px;
      line-height: 18px;
      color: #00678B;
      text-align: left;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .productItemMetadata {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 4px;
      width: 100%;
      > div {
        margin-top: 6px;
      }
    }
  }
}

.productItemOrderListHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  color: rgb(7, 14, 50);
  font-size: 14px;

  .productItemheaderRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    box-sizing: border-box;
    width: 100%;
    background: '#FFF';
    padding: 0;
    border-top: 1px solid rgba(7, 14, 50, 0.1);
    .productItemproductBox {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      flex-wrap: wrap;
      &.productBoxHeader {
        margin-bottom: -10px;
        > * {
          margin-bottom: 10px;
        }
      }
    }
  }

  .productInfo,
  .quantity,
  .productItemtotal {
    padding-right: 16px;
  }
  .productItemaction {
    padding-right: 6px;
  }

  .productInfo {
    flex-shrink: 0;
    text-align: left;
    flex: 1;
  }

  .quantity {
    flex-shrink: 0;
    text-align: right;
    width: 140px;
  }

  .productItemtotal {
    flex-shrink: 0;
    text-align: right;
    width: 140px;
    padding: 16px;
    border-right: 1px solid rgba(7, 14, 50, 0.05);
  }

  .productItemaction {
    flex-shrink: 0;
    text-align: right;
    width: 164px;
    padding: 16px 24px 16px 0;
    display: flex;
    flex-direction: column;
  }
}

.orderListHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  color: rgb(7, 14, 50);
  font-size: 12px;
  margin-bottom: 12px;

  .headerRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    background-color: rgba(7, 14, 50, 0.04);
    border-bottom: 1px solid #e3e3e7;
    padding: 14px 24px;
    &.headerRowBorder {
      border: 1px solid #e3e3e7;
      border-radius: 4px;
    }
    .productBox {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      flex-wrap: wrap;
      &.productBoxHeader {
        margin-bottom: -10px;
        > * {
          margin-bottom: 10px;
        }
      }
    }
  }

  .productInfo,
  .quantity,
  .total {
    padding-right: 16px;
  }
  .action {
    padding-right: 6px;
  }

  .productInfo {
    flex-shrink: 0;
    text-align: left;
    flex: 1;
  }

  .quantity {
    flex-shrink: 0;
    text-align: right;
    width: 140px;
  }

  .total {
    flex-shrink: 0;
    text-align: right;
    width: 140px;
  }

  .action {
    flex-shrink: 0;
    text-align: right;
    width: 140px;
  }
}

.orderlist {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  &_menu {
    height: 100%;
    width: 210px;
    margin-right: 20px;
  }

  &_content {
    flex: 1;
    flex-shrink: 0;
    min-width: 0;
  }
}

.trackingPath {
  color: rgb(34, 127, 255);
  font-size: 14px;
  margin-top: 7px;
  cursor: pointer;
}

.crumbs_arrow {
  width: 12px;
  height: 12px;
  margin-left: 6px;
  font-style: normal;
  color: #8c8d95;
  transform: rotate(90deg);
  display: inline-block;
  vertical-align: bottom;
  background: url('/assets/images/international/searchList/icon_right_title.png') 0 0 / 100% 100%;
  // background:url(/assets/images/international/searchList/icon_right_title.png) 0 0 no-repeat;
}
.crumbs_arrow1 {
  width: 12px;
  height: 12px;
  margin-left: 6px;
  display: inline-block;
  vertical-align: bottom;
  font-style: normal;
  color: #8c8d95;
  transform: rotate(-90deg);
  background: url('/assets/images/international/searchList/icon_right_title.png') 0 0 / 100% 100%;
  // background:url(/assets/images/international/searchList/icon_right_title.png) 0 0 no-repeat;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: 0 auto;
  // width: 960px;
  padding: 10px 0;
  font-size: 12px;
}

.container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  // width: 1200px;
  margin-bottom: 80px;
}

.app {
  text-align: center;
  background-repeat: no-repeat;
  width: 100%;
  margin: 0 auto;
}

.orderList {
  border-top: 1px solid rgba(7, 14, 50, 0.05);
  .header {
    background: rgba(0, 3, 20, 0.08);
    padding: 10px 0;
    color: #000314;
    margin-top: 20px;
  }
  .title1 {
    width: 95px;
  }

  .title2 {
    width: 210px;
    .biaopin {
      float: left;
      padding: 0 2px;
      height: 16px;
      display: inline;
      color: #ffffff;
      font-size: 12px;
      font-weight: normal;
      background: linear-gradient(90deg, rgba(111, 131, 242, 1) 0%, rgba(67, 93, 240, 1) 100%);
    }
  }

  .title3 {
    width: 160px;
  }

  .title4 {
    width: 100px;
  }

  .title5 {
    width: 115px;
  }

  .title6 {
    width: 140px;
  }
  .title9 {
    width: 120px;
  }
  .deliveryModal {
    position: absolute;
    width: 300px;
    height: 200px;
    z-index: 9999;
    background-color: #fff;
    left: -300px;
    overflow: scroll;
  }
  .hide {
    display: none;
  }
  .show {
    display: block;
  }
  .titleArrow {
    display: flex;
    align-items: center;
    cursor: pointer;
    .crumbs_arrow {
      float: left;
      width: 4px;
      height: 7px;
      margin: 0px 0px 0 4px;
      font-style: normal;
      color: #999999;
      background: url('/assets/images/international/searchList/icon_right_orderList.png') 0 0 / 100% 100%;
      // background:url(/assets/images/international/searchList/icon_right_title.png) 0 0 no-repeat;
    }
  }

  .title7 {
    flex: 1;
  }
  .title8 {
    width: 100px;
  }

  .selectedTitle {
    margin-right: 20px;
    color: #00678b;
  }
}

.trashOrderList {
  .title1 {
    width: 124px;
  }

  .title2 {
    width: 241px;
  }

  .title3 {
    width: 194px;
  }

  .title4 {
    width: 139px;
  }

  .title5 {
    width: 160px;
  }

  .title6 {
    width: 130px;
  }

  .title7 {
    flex: 1;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.goodsCell {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
}

.borderLeft {
  border-left: 1px solid #e1e1e1;
}

.borderTop {
  border-top: 1px solid #e1e1e1;
}

.borderBottom {
  border-bottom: 1px solid #e1e1e1;
}

.borderRight {
  border-right: 1px solid #e1e1e1;
}

.red {
  color: $red-color;
}

.orange {
  color: rgba(237, 85, 2, 1);
}
.green {
  color: rgba(2, 161, 18, 1);
}

.blue {
  color: $blue-color;
}
.black {
  color: $text-3-color;
}

.normal {
  font-weight: normal;
}
.car {
  width: 20px;
  height: 20px;
}

.ellipsis {
  display: flex;
  cursor: pointer;
}

.buyAccountModal {
  :global {
    .ant-input-affix-wrapper {
      height: 40px;
    }
  }
}

.emptyList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0 68px 0;
}

.goodsName {
  @include ellipsis(2);

  color: rgba(51, 51, 51, 0.85);
  font-weight: bold;
  clear: both;
  &:hover {
    color: $blue-color-hover;
  }
}

.blod {
  font-weight: bold;
}
.checkAll {
  margin-left: 18px;
  position: relative;
}

.selectNum {
  color: #00678b;
}

.batchActions {
  display: flex;
  align-items: center;
  padding: 14px 0;
  justify-content: space-between;
  :global {
    // .ant-checkbox-wrapper .ant-checkbox{
    //   top:2px
    // }
    // .ant-checkbox-wrapper span{
    //   font-size: 12px;
    // }

    // .ant-btn{
    //   font-size: 12px;
    //   background-color: white;
    //   border-color: rgba(0,3,20,0.15);
    // }
    // .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active{
    //   border-color: rgba(0,3,20,0.15);
    //   background-color: white;
    //   color:rgba(0,3,20,0.25);
    // }
    // .ant-btn:hover, .ant-btn:focus{
    //   background-color:#fff;
    // }
  }
  &_orderDownload {
    margin-right: 8px;

    &_vertical {
      margin-left: 8px;
      width: 1px;
      height: 12px;
      color: #dcdcdc;
    }
  }

  :global {
    // .ant-btn:not([disabled]) {
    //   &:hover {
    //     color:  rgba(67,93,240,1);;
    //     border-color:  rgba(67,93,240,0.45);
    //     background-color: #fff;
    //   }
    // }

    // .ant-btn {
    //   padding: 4px 8px;
    // }
  }

  .showMore {
    position: relative;
    margin-left: 5px;
  }

  .showMore_icon_more {
    display: none;
  }

  .showMore_icon:hover ~ .showMore_icon_more {
    display: block;
    padding: 12px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    color: #666;
    font-size: 12px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
    position: absolute;
    z-index: 10000;
  }
}

.link {
  &:hover {
    color: $blue-color !important;
  }
}

.tit_tip {
  background: rgba(0, 3, 20, 0.08);
  padding: 10px 0;
  color: #ec9d1b;
  margin-top: 20px;
  display: flex;
  align-items: center;
  img {
    margin-left: 20px;
    margin-right: 10px;
  }
}

.receiver_title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.receiver_detail {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}

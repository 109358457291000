/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

@import '@/assets/style/config';

.container {
  :global {
    .ant-input,
    .ant-picker,.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 32px;
      border: 1px solid rgba(0,0,0,0.15);
    }
  }
}
ccountSearchForm {
  :global {
    .ant-form-item-label {
      width: 90px;
    }

    .ant-space-item {
      width: 550px;
    }

    .ant-form-item-explain,
    .ant-form-item-extra {
      font-size: 12px;
    }
    .ant-input,
    .ant-picker,.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 32px;
      border: 1px solid rgba(0,0,0,0.15);
    }
  }
}

.searchForm {
  :global {
    .ant-form-item-label {
      width: 90px;
    }

    .ant-space-item {
      width: 550px;

      &:first-child {
        width: 100%;
      }
    }

    .ant-radio-button-wrapper {
      width: 110px;
      height: 32px;
      line-height: 27px;
      margin-right: 15px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      color: #333;
      position: relative;
      text-align: center;
      font-size: 12px;
      border-radius: 2px;
      overflow: hidden;
    }

    .ant-radio {
      width: 110px;
      height: 32px;
      margin-right: 15px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      color: #333;
      position: relative;
      font-size: 12px;
    }

    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: $blue-color;
      background: #fff;
      border: 1px solid $blue-color;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      content: none;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::after {
      content: '';
      width: 15px;
      height: 15px;
      background:
        url('https://s2-relay.360buyimg.com/relay/i/3/b0ee493eca5971060ec6ab9344ab00e6111d38212cf93ca7bcdd62936b572e0d.svg')
        0 0 / 100% 100%;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    .ant-form-item-explain,
    .ant-form-item-extra {
      font-size: 12px;
    }
  }
}

.buyAccountList {
  .title1 {
    width: 40px;
    :global{
      .ant-checkbox-wrapper{
        height: 16px;
      }
    }
  }

  .title2 {
    width: 250px;
  }

  .title3 {
    width: 140px;
  }

  .title4 {
    width: 140px;
  }

  .title5 {
    width: 410px;
  }

  .title6 {
    width: 180px;
  }
}

.nodeList {
  .title1 {
    width: 120px;
  }

  .title2 {
    width: 270px;
  }

  .title3 {
    width: 620px;
  }

  .title4 {
    width: 140px;
  }
}

.investigateAccountList {
  .title1 {
    width: 40px;
    .ant-checkbox-wrapper{
      height: 16px;
    }
  }

  .title2 {
    width: 250px;
  }

  .title3 {
    width: 140px;
  }

  .title4 {
    width: 240px;
  }

  .title5 {
    width: 240px;
  }

  .title6 {
    width: 240px;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000314;
}

.goodsCell {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 5px;
}

.borderTop {
  border-top: 1px solid #eee;
}

.borderBottom {
  border-bottom: 1px solid #eee;
}

.borderLeft {
  border-left: 1px solid #eee;
}

.borderRight {
  border-right: 1px solid #eee;
}

.red {
  color: $red-color;
}

.blue {
  color: $blue-color;
}

.bold {
  font-weight: bold;
}

.emptyList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0 68px 0;
  border: 1px solid #eee;
}

.upload {
  width: 90px;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #e1e1e1;
  border-radius: 4px;
  color: #aaa;
  font-size: 12px;
  flex-shrink: 0;
  overflow: hidden;

  .dot {
    background-color: #f3f3f3;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  &:hover {
    border: 1px dashed $blue-color;
    color: $blue-color;

    .dot {
      background-color: #e5f0fb;
    }
  }
}

.checkAll {
  :global {
    .ant-checkbox-inner {
      background-color: transparent !important;
      border: 1px solid rgba(0,3,20,0.25)!important;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #fff !important;
      border-color: #fff !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      border: 2px solid $blue-color;
      border-top: 0;
      border-left: 0;
      top: 42% !important;
      left: 18% !important;
      transition: none;
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner {
      background-color: #fff !important;
      border-color: #fff !important;
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      top: 5px !important;
      left: 1px !important;
      background-color: $blue-color !important;
      width: 10px;
      height: 2px;
      border: none;
      transform: none;
      transition: none;
    }
  }
}

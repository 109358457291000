/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.addNodeBtnBox{
  width: 240px;
  display: inline-flex;
  flex-shrink: 0;
  -webkit-box-flex: 1;
  position: relative;
  .addNodeBtn {
    user-select: none;
    width: 240px;
    padding: 16px 0 29px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    &.smallNodeBtn{
      padding: 0 0 29px;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 1px;
      height: 100%;
      background-color: #D8D8D8;
    }
    .btn {
      position: relative;
      width: 20px;
      height: 20px;
      cursor: pointer;
      z-index: 1;
      &:hover {
        .addPanel{
          display: block;
        }
        i{
          font-size: 20px;
          color: #435DF0 ;
        }
      }
      i{
        font-size: 20px;
        color: #00678B ;
      }
      .addPanel{
        display: none;
        position: absolute;
        top: -174px;
        left: -106px;
        width: 232px;
        height: 176px;
        .addContent{
          position: relative;
          width: 232px;
          height: 166px;
          background: #ffffff;
          z-index: 999;
          border: 1px solid rgba(0,3,20,0.05);
          border-radius: 4px;
          box-shadow:  0 4px 8px 0 rgba(0,3,20,0.05);
          &::after{
            content: '';
            width: 11px;
            height: 11px;
            background: url("../../../../../../assets/images/approval/icon-arrow-white.svg") no-repeat center;
            position: absolute;
            bottom: -8px;
            left: 109px;
          }
          .panelTitle{
            width: 100%;
            height: 54px;
            line-height: 54px;
            text-align: center;
            color: $text-085-color;
            font-weight: 500;
          }
          .panelItems{
            display: flex;
            align-items: center;
            justify-content: center;
            .panelItem{
              width: 88px;
              height: 88px;
              text-align: center;
              cursor: pointer;
              span{
                display: inline-block;
                margin-top: 12px;
                font-size: 14px;
                color: $text-065-color;
              }
              &:hover{
                span{
                  color: $blue-new-hover-color;
                }
              }
            }
            //.node{
            //  background: #F5F6FA url("../../../../../../assets/images/approval/icon-condition.svg") no-repeat center 20px;
            //  &:hover{
            //    background: #F5F6FA url("../../../../../../assets/images/approval/icon-condition-hover.svg") no-repeat center 20px;
            //  }
            //}
            .node, .condition{
              margin-left: 8px;
              background: #F5F6FA;
              div{
                margin-top: 20px;
                i{
                  font-size: 30px;
                  color: rgba(0,3,20,0.65);
                }
              }
              &:hover{
                //background: #F5F6FA url("../../../../../../assets/images/approval/icon-approval-hover.svg") no-repeat center 20px;
                div{
                  i{
                    font-size: 30px;
                    color: #00678B;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

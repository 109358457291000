/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.addressCard {
  padding-bottom: 8px;
  .line {
    height: 1px;
    border-radius: 4px;
    background: #E7E9EA;
    margin-left: 16px;
    margin-top: 8px;
  }
  .addressContent {
    flex: 1;
    padding: 12px 16px;
    border-radius: 4px;
    cursor: pointer;
    .addressContentIcon {
      display: none;
    }
    &:hover {
      background: rgba(0, 103, 139, 0.05);
    }
    &.addressContentActive {
      border: 1px solid #00678B;
      background: rgba(0, 103, 139, 0.05);
      position: relative;
      .addressContentIcon {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 16px;
        height: 16px;
      }
    }
  }

  .contactInfo {
    display: flex;
    margin-bottom: 8px;
  }

  .companyName {
    color: #070E32;
  }

  .phoneNumber {
    color: #262937;
    margin-left: 30px;
  }

  .addressDetails {
    margin: 0;
    color: #838698;
    line-height: 1.4;
  }

  .editIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.drawerlistbox {
  :global {
    .ant-drawer-header {
      border-bottom: none;
      padding: 24px;
    }

    .ant-drawer-body {
      padding: 0 !important;
    }

    .ant-drawer-close {
      padding: 27px;
    }
  }
}

.drawertitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drawerlist {
  width: 100%;
  height: 100%;

  &_searchbox {
    padding: 0 24px;
    margin-bottom: 16px;
    // >span{
    //   border: 1px solid #e6e6e6;
    // }
  }

  &_listbox {
    width: 100%;
    height: calc(100% - 122px);
    padding: 0 24px;
    overflow: hidden;
    overflow-y: auto;

    ul {
      .listbox_li {
        width: 100%;
        font-size: 14px;
        color: rgba(0, 3, 20, 0.85);
        background: rgba(245, 246, 250, 1);
        border-radius: 2px;
        font-weight: 500;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 14px;
        cursor: pointer;
        position: relative;
        border: 1px solid transparent;

        &_tag {
          b {
            text-decoration: none;
            padding: 2px 4px;
            font-size: 12px;
            color: rgba(255, 255, 255, 1);
            background: rgba(25, 56, 237, 1);
            position: absolute;
            top: 0;
            right: 0px;
            border-radius: 2px;
          }
        }
      }

      .listbox_li:hover {
        border: 1px solid rgba(25, 56, 237, 1);
      }

      .listbox_li_active {
        border: 1px solid rgba(25, 56, 237, 1);
      }
    }
  }

  &_create {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;

    &_button {
      background: rgba(25, 56, 237, 1);
      border-radius: 2px;
      color: rgba(255, 255, 255, 1) !important;
      line-height: 20px;
      font-size: 14px;
      padding: 6px 16px;
      cursor: pointer;
    }
  }
}

.m {
  width: 100%;
  padding: 0 24px;
}

.item,
.default {
  margin-bottom: 24px;

  &.hasError {
    margin-bottom: 0;
  }
}

.content {
  width: 100%;
}

.label {
  font-size: 14px;
  color: #000314;
  line-height: 20px;
  margin-bottom: 8px;

  em {
    color: #e50c00;
    padding-right: 5px;
  }
}

.wrapper {
  border: 1px solid rgba(0, 3, 20, 0.15);
  border-radius: 2px;

  input {
    height: 40px;
    padding-left: 12px;
    width: 100%;
    border: none;
    outline: 0;

    &::placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}

.default {
  .wrapper {
    padding: 5px 0;
    border: 0;
    display: flex;
    align-items: center;
  }

  input {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
    padding: 0;
    width: 32px;
    height: 16px;
    margin: 0;
    cursor: pointer;

    & + s {
      display: flex;
      width: 40px;
      height: 20px;
      background: rgba(0, 0, 0, 0.15);
      border: 1px solid rgba(0, 0, 0, 0.09);
      border-radius: 12px;
    }

    // Box.
    & + s::before {
      content: '';
      border-radius: 14px;
      width: 18px;
      height: 18px;
      background: rgba(255, 255, 255, 1);
    }

    // Box checked
    &:checked + s {
      background: #00678B;
      justify-content: flex-end;
    }
  }
}

.errors {
  display: flex;
  padding: 3px 0;
  color: #e50c00;
  font-size: 12px;

  i {
    position: relative;
    margin: 1px 3px;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    background-color: #e50c00;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: '';
      width: 1px;
      height: 6px;
      background-color: white;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.add {
  margin-right: 10px;
  padding: 4px 8px;
  border-radius: 14px;
  font-size: 12px;
  color: #000314;
  background-color: white;
  border: 1px solid #eee;
  text-align: center;
  cursor: pointer;
  width: 28px;

  &:hover,
  &.active {
    background-color: royalblue;
    border-color: royalblue;
    color: white;
  }
}

.mb {
  position: fixed;
  bottom: 24px;
  right: 24px;

  button,
  .button {
    padding: 6px 16px;
    border-radius: 2px;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    border: none;

    &.primary {
      background-color: #00678B;
      color: white;
    }
  }
}

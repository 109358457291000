/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.addAccountModel{
  :global{
    .ant-drawer-header{
      padding: 24px !important;
    }
    .ant-drawer-body{
      padding: 0 24px 24px 24px !important;
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      overflow-x: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
    }
    .ant-drawer-footer{
      padding:0 24px !important;
      border: none !important;
    }
    .ant-drawer-close{
      padding:20px 10px !important;
    }
  }
  .iconClose{
      position: fixed;
      width: 16px;
      height: 16px;
      float: right;
      cursor: pointer;
      right: 22px;
      // margin-right: 6px;
      background: url("../../../../../assets/images/common/icon-close.svg") center no-repeat;
  }
  .addContent{
    width: 100%;
    min-height: calc(100% - 160px);
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    position: relative;
    //overflow-y: auto;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .nodeName{
      position: relative;
      display: flex;
      height: 32px;
      line-height: 32px;
      margin-bottom: 16px;
      .title{
        font-size: 14px;
        color: $text-085-color;
        flex: 1;
        white-space: nowrap;
      }
      input{
        width: 652px;
        height: 32px;
        line-height: 32px;
        border: 1px solid $text-015-color;
        border-radius: 4px;
        text-indent: 12px;
        color: $text-085-color;
        font-size: 14px;
        &::placeholder{
          font-size: 14px;
          color: $text-025-color;
        }
        &.error{
          border: 1px solid #E00224;
        }
      }
      .inputTips{
        position: absolute;
        font-size: 14px;
        top: 0;
        right: 12px;
        color: $text-025-color;
      }
      .errorTips{
        position: absolute;
        top: 28px;
        left: 100px;
        font-size: 12px;
        color: #E00224;
      }
    }
    .searchAccount{
      width: 100%;
      height: 32px;
      line-height: 32px;
      border: 1px solid $text-015-color;
      border-radius: 4px;
      text-indent: 28px;
      font-size: 14px;
      background: url("../../../../../assets/images/common/icon-search.svg") 9px center  no-repeat;
      margin-bottom: 16px;
      &::placeholder{
        font-size: 14px;
        color: $text-025-color;
      }
    }
    .empty{
      width: 100%;
      height: 700px;
      display: flex;
      align-items: center;
      justify-content: center;
      .content{
        width: 80px;
        height: 100px;
        .emptyIcon{
          display: block;
          width: 80px;
          height: 80px;
          // background: url("../../../../../assets/images/empty/empty_new.svg") no-repeat center;
          background-size: cover;
        }
        .emptyTitle{
          display: inline-block;
          width: 80px;
          text-align: center;
          font-size: 14px;
          color: $text-045-color;
        }
      }
    }
  }
  .addHeader{
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: $text-003-color;
    font-size: 14px;
    font-weight: normal;
    color: $text-045-color;
    border: 1px solid $text-015-color;
    border-bottom: none;
    .name{
      display: inline-block;
      width: 334px;
      text-indent: 48px;
    }
    .email{
      display: inline-block;
      width: 257px;
    }
  }
  .addMain{
    border: 1px solid $text-015-color;
    border-top: none;
    font-size: 14px;
    color: $text-085-color;
    position: relative;
    .mainItem{
      height: 40px;
      line-height: 40px;
      .checkbox{
        display: inline-block;
        width: 48px;
        text-align: center;
      }
      .name{
        display: inline-block;
        width: 285px;
      }
      .email{
        display: inline-block;
        width: 257px;
      }

    }
    .mainItem:hover{
      background: $background-100-color;
    }
  }
  .pageMain{
    width: 100%;
    height: 20px;
    line-height: 20px;
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    color: $text-045-color;
    .pageTips{
      margin-right: 16px;
    }
    .pageNext,.pagePre{
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
    .pagePre{
      background: url("../../../../../assets/images/approval/icon-page-pre.svg") no-repeat center;
      &.disable{
        cursor: not-allowed;
        background: url("../../../../../assets/images/approval/icon-page-pre-gray.svg") no-repeat center;
      }
    }
    .pageNext{
      background: url("../../../../../assets/images/approval/icon-page-next.svg") no-repeat center;
      &.disable{
        cursor: not-allowed;
        background: url("../../../../../assets/images/approval/icon-page-next-gray.svg") no-repeat center;
      }
    }
    .pageIndex{
      margin: 0 8px;
      em{
        color: $blue-new-color;
      }
    }

  }
  .addFooter{
   width: 100%;
   height: 64px;
   display: flex;
   justify-content: left;
   align-items: center;
   .footerLeft{
     flex: 1;
     text-align: left;
     display: flex;
     align-items: center;
     .leftCheck{
       display: flex;
       align-items: center;
       font-size: 14px;
       color: $text-085-color;
       margin-left: 18px;
       .allTitle{
         margin-left: 6px;
       }
     }
     .leftTips{
       font-size: 14px;
       color: $text-045-color;
       margin-left: 16px;
     }
   }
  }
}

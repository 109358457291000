/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

//商品清单
.col1 {
  flex: 1;
  text-align: left;
}
.col2,
.col3,
.col4 {
  width: 130px;
}

.col3 {
  padding-left: 20px!important;
  padding-right: 20px!important;
}

.col5 {
  width: 190px;
  padding-left: 20px!important;
}

.col2,
.col3,
.col4,
.col5 {
  text-align: right;
}

.goodsInfo {
  border: 1px solid rgba(7, 14, 50, 0.1);
  border-radius: 0 0 4px 4px;
}

.productListHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 14px 24px;
  background-color: rgba(7, 14, 50, 0.04);
  border: 1px solid #e3e3e7;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  color: rgb(7, 14, 50);

  .productInfo,
  .price,
  .quantity,
  .total,
  .action {
    flex-shrink: 0;
    font-size: 12px;
    line-height: 1.17;
  }
}

.goodsList {
  background-color: #fff;
  padding-bottom: 20px;
  .title {
    flex-shrink: 0;
    font-size: 24px;
    line-height: 1.17;
    color: rgb(0, 3, 20);
    text-align: left;
    margin-bottom: 24px;
  }

  .boxs {
    .gList {
      width: 100%;
      padding: 0 20px 0 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      .item {
        padding-left: 16px;
        box-sizing: border-box;
        text-align: center;
        &:last-child {
          padding-left: 0;
        }
        .single {
          // height: 18px;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: $text-6-color;

          &.s {
            color: $text-3-color;
          }

          &.b {
            font-weight: bold;
          }
        }

        .remark {
          font-size: 12px;
          line-height: 17px;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        .remark_hover {
          position: absolute;
          width: 228px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.1);
          padding: 9px 16px;
          color: rgba(102, 102, 102, 1);
          font-size: 12px;
          line-height: 17px;
          left: 50px;
          z-index: 1000;
          display: none;
        }

        .remark:hover ~ .remark_hover {
          display: block;
        }

        .price {
          display: inline-block;
          height: 18px;
          padding: 0 0 0 17px;
          background: url(../../../../assets/images/order_detail/close-line@2x.png) no-repeat left center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
          color: #262626;
          line-height: 18px;
          background-size: 12px;
        }

        .btn {
          display: inline-block;
          padding: 7px 0;
          background: $price-color;
          border-radius: 2px;
          line-height: 18px;
          font-size: 12px;
          color: #fff;
          width: 100px;
          text-align: center;
          text-decoration: none;
          cursor: pointer;

          &.dis {
            cursor: not-allowed;
            background: #e5e5e5;

            &:hover {
              background: #e5e5e5;
            }
          }

          &:hover {
            background: #205fc7;
          }
        }

        &.a {
          width: 300px;
          flex-shrink: 0;
          text-align: left;
        }

        &.b {
          flex-shrink: 0;
          width: 110px;
        }

        &.c {
          flex-shrink: 0;
          width: 133px;
        }

        &.d {
          flex-shrink: 0;
        }

        &.e {
          flex-shrink: 0;
          width: 130px;
        }

        &.f {
          flex-shrink: 0;
          width: 135px;
        }

        &.g {
          flex-shrink: 0;
          width: 72px;
          position: relative;
        }
      }

      &.top {
        background: $table-header;
        border: 1px solid #e1e1e1;
        border-bottom: none;
        height: 38px;
        line-height: 38px;

        li {
          color: $text-9-color;
          text-align: center;
          border-right: none;

          &.a {
            text-align: left;
          }
        }
      }

      &.bottom {
        border-bottom: 1px solid #e1e1e1;
        &:nth-last-of-type(1) {
          border-bottom: none;
        }
        .col1,
        .col2,
        .col3,
        .col4,
        .col5 {
          padding: 24px 0 30px 0;
        }
        .col1 {
          padding-left: 24px;
        }
        .col3,
        .col5 {
          padding-top: 28px;
        }

        .item {
          &.a {
            .top {
              display: flex;
              .img {
                width: 72px;
                height: 72px;
                border: 1px solid #dfe1e4;
                border-radius: 2px;
              }
              .dess {
                margin-top: 14px;
                display: flex;
                flex-direction: column;
                > div {
                  margin-top: 6px;
                }
              }
              .right {
                margin: 0 0 0 12px;
                flex: 1;
                padding-right: 20px;
                .name {
                  color: #00678b;
                  font-size: 14px;
                  font-family: Arial;
                  font-weight: 400;
                  line-height: 16px;
                  letter-spacing: 0px;
                  display: block;
                  width: 100%;
                  word-break: break-all;
                }

                .goodsNum {
                  display: flex;
                  text-align: left;
                  justify-content: space-between;
                  em {
                    font-style: normal;
                  }

                  .text {
                    display: inline-block;
                    color: $text-6-color;
                    line-height: 18px;
                    vertical-align: middle;
                  }

                  .b {
                    display: inline-block;
                    color: $text-3-color;
                    line-height: 18px;
                    height: 18px;
                    flex: 1;
                  }

                  .copy {
                    display: inline-block;
                    cursor: pointer;
                    width: 16px;
                    height: 18px;
                    background: url(../../../../assets/images/order_detail/copy-fill@2x.png) no-repeat center;
                    margin: 0 0 0 8px;
                    background-size: 16px;
                    vertical-align: middle;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

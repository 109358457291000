/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.crumbs {
  margin: 14px 0 24px;
  font-size: 14px;
  width: 100%;

  a {
    text-decoration: none;
  }

  .clr {
    display: block;
    overflow: hidden;
    clear: both;
  }

  .text_space {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-style: normal;
  }

  .crumbs_nav_main {
    display: flex;
    align-items: center;

    .clearfix {
      content: '.';
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }

    // overflow: hidden;
    .crumbs_nav_item {
      float: left;
      margin: 0px 5px 0px 0;
      color: #222222;

      > a {
        color: #8c8d95;
        font-size: 12px;
      }

      > strong {
        font-size: 12px;
        margin-right: 5px;
        font-weight: bold;
        color: #222222;
      }
    }

    .crumbs_arrow {
      float: left;
      width: 12px;
      height: 12px;
      margin: 0px 7px 0 7px;
      font-style: normal;
      color: #999999;
      background: url('/assets/images/international/searchList/icon_right_title.png') 0 0 / 100% 100%;
      // background:url(/assets/images/international/searchList/icon_right_title.png) 0 0 no-repeat;
    }

    .select_con {
      float: left;
      margin-top: 1px;

      //margin-left: 5px;
      .crumb_select_item {
        position: relative;
        float: left;
        height: 26px;
        line-height: 26px;
        border: 1px solid #ddd;
        font-size: 14px;
        margin: 0 5px 0px 0;
        padding: 0 4px 0 4px;
        cursor: pointer;
        background: #fff;

        &:hover {
          border-color: #00678b;

          > em {
            color: #00678b;
          }

          > i {
            background: url('/assets/images/searchList/nav_close_act.svg') 0 0 / 100% 100%;
          }
        }

        > b {
          display: inline-block;
          font-weight: 400;
          color: #999;
          vertical-align: 8px;
        }

        > em {
          position: relative;
          display: inline-block;
          max-width: 158px;
          line-height: 24px;
          color: #333;
          padding-right: 25px;
          vertical-align: top;
          // &::before {
          //   content: "";
          //   position: absolute;
          //   width: 1px;
          //   height: 15px;
          //   background: rgba(204, 204, 204, 1);
          //   top: 4px;
          //   right: 0;
          // }
        }

        > i {
          display: block;
          position: absolute;
          width: 17px;
          height: 17px;
          right: 4px;
          top: 2px;
          background: url('/assets/images/searchList/nav_close.svg') 0 0 / 100% 100%;
          border-left: 1px solid #ccc;
        }
      }
    }

    .crumbs_level {
      float: left;
      margin-right: 10px;

      ul {
        li {
          display: inline-block;
          color: #666666;
          font-size: 14px;
          font-weight: 500;

          span {
            cursor: pointer;
          }

          i {
            display: inline-block;
            margin: 0 5px;
            font-style: normal;
            margin-left: 10px;
          }
        }
      }
    }

    .clear_selected {
      > a {
        color: #999999;
      }

      &:hover {
        > a {
          color: crimson;
        }
      }
    }

    .top_cate {
      float: left;
      font-size: 12px;

      .first {
        float: left;
        color: #868686;
        font-weight: 400;
      }
    }

    .categories {
      float: left;
      .first {
        margin-left: 10px;
        margin-right: 10px;
      }

      .menu_drop,
      .selectorSet {
        float: left;
        //margin-right: 5px;
      }

      .menu_drop {
        height: 22px;

        .trigger {
          display: inline-block;
          height: 22px;
          padding: 3px 5px;
          border: 1px solid #d7d7d7;
          vertical-align: top;
          background-color: #fff;
          font-size: 12px;
          line-height: 14px;
          cursor: pointer;
          border-radius: 2px;

          .curr {
            color: #333;
            vertical-align: top;
            display: inline-block;
          }

          .menu_drop_arrow {
            position: relative;
            top: 1px;
            display: inline-block;
            width: 18px;
            height: 12px;
            margin-left: 6px;
            padding-left: 5px;
            border-left: 0.5px solid #D3D3D3;
            background: url('https://jdios.jdindustry.com/mubla1/23906451373485552.png') 0 0 / 10px auto;
            background-repeat: no-repeat;
            background-position: center;
          }

          &:hover {
            // .curr {
            //   color: #00678B;
            // }

            .menu_drop_arrow {
              background-repeat: no-repeat;
            }
          }
        }

        .menu_drop_main {
          display: none;
          padding: 14px;
          position: absolute;
          min-width: 100%;
          left: 0;
          top: 23px;
          border: 1px solid #ddd;
          background-color: #fff;

          .menu_drop_list {
            display: flex;
            flex-direction: column;
            li {
              line-height: 20px;
              padding: 3px 0px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              a {
                color: rgba(7, 14, 50, 0.5);
                font-size: 12px;
                cursor: pointer;

                &:hover {
                  color: #00678b;
                  text-decoration: underline;
                }
              }
            }
            li:nth-child(even) {
              margin-right: 0px;
            }
          }
        }
      }

      .z_menu_drop_open {
        position: relative;
        z-index: 5;

        .trigger {
          position: relative;
          z-index: 6;
          height: 24px;
          border-bottom: none;

          .curr {
            color: #00678b;
            text-decoration: underline;
          }

          .menu_drop_arrow {
            background: url('https://jdios.jdindustry.com/mubla1/196389923498133384.png') 0 0 / 10px auto;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        &:hover {
          .trigger {
            border-bottom: none;
            height: 24px;

            .menu_arop_arrow {
              background: url('https://jdios.jdindustry.com/mubla1/196389923498133384.png') 0 0 / auto auto;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }

        .menu_drop_main {
          display: block;
        }
      }

      .select_con {
        float: left;

        //margin-left: 5px;
        .crumb_select_item {
          position: relative;
          float: left;
          height: 24px;
          line-height: 24px;
          border: 1px solid #ddd;
          font-size: 14px;
          margin: 0 5px 5px 0;
          padding: 0 30px 0 4px;
          cursor: pointer;
          background: #fff;

          &:hover {
            border-color: #00678b;

            > em {
              color: #00678b;
            }

            > i {
              background: url('/assets/images/searchList/nav_close_act.svg') 0 0 / 100% 100%;
            }
          }

          > b {
            display: inline-block;
            font-weight: 400;
            color: #666;
            vertical-align: 8px;
          }

          > em {
            position: relative;
            display: inline-block;
            max-width: 118px;
            line-height: 24px;
            color: #333;
            padding-right: 6px;
            vertical-align: top;

            &::before {
              content: '';
              position: absolute;
              width: 1px;
              height: 15px;
              background: rgba(204, 204, 204, 1);
              top: 4px;
              right: 0;
            }
          }

          > i {
            display: block;
            position: absolute;
            width: 12px;
            height: 12px;
            right: 9px;
            top: 5px;
            background: url('/assets/images/searchList/nav_close.svg') 0 0 / 100% 100%;
          }
        }
      }
    }

    .oneLevel {
      .searchNav {
        margin-right: 10px;
      }
    }
  }
}

/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.larger_img {
  width: 100%;
  margin: 0 auto;
  // padding: 0 0 10px 0;
  ;
  overflow: hidden;
  position: relative;

.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 300px;
  height: 100%;
}
  .gl_warp {
    width: 1200px;
    overflow: hidden;
    flex-direction: row;
    justify-content: space-between;

    .gl_item {
      width: 470px;
      box-sizing: border-box;
      position: relative;
      float: left;
      border-bottom: 1px solid #EDEDED;
      &:nth-child(2n + 1) {
        border-bottom: 0px;
      }

      img {
        cursor: pointer;
      }
      .grey {
        opacity: 0.5;
      }

      .title {
        width: 210px;
        height: 34px;
        padding-left: 12px;
        line-height: 17px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #333;

        &:hover {
          >a {
            color: #0d47a0;
          }
        }

        >a {
          font-size: 12px;
          color: #333;
          font-weight: 600;
        }
      }

      .order_Num,
      .model_Num {
        font-size: 12px;
        line-height: 17px;
        color: #999;
        margin-top: 5px;
        padding-left: 12px;
      }

      .ent_price {
        margin: 8px 0 2px 12px;
        font-size: 20px;
        font-weight: 600;
        color: #e50c00;
        height: 20px;

        em {
          font-size: 16px;
        }
      }

      .jd_price {
        margin: 0 0 2px 12px;
        line-height: 22px;
        font-size: 12px;
        color: #999;
      }

      .operation {
        margin-top: 16px;
        padding-left: 12px;
        border-top: 1px solid #ededed;

        .add_shop {
          display: inline-block;
          width: 146px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          font-size: 12px;
          color: rgba(13, 71, 160, 1);
          vertical-align: top;
          cursor: pointer;

          &:hover {
            background-color: rgba(13, 71, 160, 1);
            color: #fff;
          }
        }
      }

      .stock {
        position: absolute;
        top: 206px;
        left: 0;
        z-index: 3;
        height: 24px;
        line-height: 24px;
        width: 228px;
        overflow: hidden;
        padding-left: 10px;
        color: #fff;
        //filter: progid:DXImageTransform.Microsoft.gradient(enabled='true', startColorstr='#99000000', endColorstr='#99000000');
        background: rgba(0, 0, 0, 1);
        opacity: 0.35;
        //transition: all 0.5s ease;
        font-size: 12px;
        font-weight: normal;
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .contrast_con {
    width: 1200px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: -140px;
    margin: auto;
    border: 1px solid #0d47a0;
    box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.19);
    padding: 17px 20px 23px 20px;
    background: #fff;
    transition: 0.5s;
    z-index: 99;

    .header {
      position: relative;
      margin-bottom: 20px;

      span {
        display: inline-block;
        width: 4px;
        height: 16px;
        background: #0d47a0;
        vertical-align: bottom;
      }

      h5 {
        margin-left: 10px;
        display: inline-block;
        font-size: 14px;
        color: #666;
      }

      .close {
        width: 16px;
        height: 16px;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        background: url('/assets/images/searchList/clear.png') 0 0 / 100% 100%;
        cursor: pointer;
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      height: 60px;

      .con_list {
        position: relative;
        width: 1148px;

        .list {
          display: flex;

          .item {
            display: flex;
            border-right: 1px solid #d8d8d8;
            padding-right: 10px;
            margin-right: 12px;
            background: #fff;

            &:hover {
              .right {
                .price {
                  b {
                    display: inline-block;
                  }
                }
              }
            }

            >img {
              width: 60px;
              height: 60px;
              border: 1px solid rgba(223, 223, 223, 1);
            }

            .right {
              height: 60px;
              margin-left: 8px;
              width: 124px;

              >span {
                color: #666;
                font-size: 12px;
              }

              .bt {
                width: 124px;
                height: 32px;
                line-height: 16px;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin-bottom: 10px;
                display: -ms-inline;
              }

              .price {
                b {
                  display: none;
                  color: #205fc7;
                  cursor: pointer;
                }
              }
            }
          }
        }

        .init_list {
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;

          .init_item {
            width: 191px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #f4f4f4;
            position: relative;
            font-size: 18px;
            color: #999;
            margin: 0 12px;

            >span {
              font-size: 26px;
              vertical-align: bottom;
            }

            &::after {
              content: '';
              width: 1px;
              height: 60px;
              position: absolute;
              top: 0;
              right: -12px;
              background: #d8d8d8;
            }
          }

          .init_item:nth-child(1) {
            margin-left: 0;
          }

          .init_item:last-child {
            &::after {
              display: none;
            }
          }
        }
      }

      .btns {
        width: 74px;
        margin-top: -7px;

        >a {
          display: inline-block;
          width: 74px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          border-radius: 2px;
        }

        .go_contrast {
          &.active {
            background: #0d47a0;
            color: #fff;
            margin-bottom: 10px;
          }

          background: #dfdfdf;
          color: #fff;
          margin-bottom: 10px;
          cursor: default;
        }

        .clear_contrast {
          color: #333;
          border: 1px solid rgba(16, 0, 0, 0.25);
        }
      }
    }

    &.contrast_curr {
      bottom: 0;
    }
  }

  .no_data {
    width: 250px;
    margin: 0 auto;
    text-align: center;

    >img {
      width: 100%;
    }

    .title {
      display: inline-block;
      margin-top: 17px;
      font-size: 14px;
      font-weight: normal;
      color: rgba(153, 153, 153, 1);
    }
  }
}
/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.m {
  background-color: white;
  font-size: 12px;
  display: flex;
  position: relative;

  .shippingAddressContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 19px 24px 29px;
    border: 1px solid rgba(7, 14, 50, 0.1);
    border-radius: 4px;
    &.onlyAddressTitle {
      padding-bottom: 0;
      .addressSection {
        .addressTitle {
          border-bottom: none;
        }
      }
    }
    .addressSection {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      box-sizing: border-box;
      width: 100%;
      .addressTitle {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 17px;
        border-bottom: 1px solid rgba(7, 14, 50, 0.1);
      }
    }

    .addressDetails {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: center;
      width: 100%;
      margin-top: 17px;
    }

    .changeAddressButton {
      flex-shrink: 0;
      font-size: 14px;
      line-height: 1;
      color: rgb(0, 103, 139);
      text-align: right;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    .addressInfo {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 70%;
      color: rgb(7, 14, 50);
    }

    .addressLine {
      flex-shrink: 0;
      margin-top: 14px;
      font-size: 14px;
      line-height: 1.43;
      text-align: left;
    }

    .contactInfo {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 216px;
      height: 16px;
    }

    .recipientPhone {
      flex-shrink: 0;
      margin-left: 46px;
      font-size: 14px;
      line-height: 1.14;
      text-align: left;
      font-weight: 500;
    }

    .recipientName {
      flex-shrink: 0;
      font-size: 14px;
      line-height: 1.14;
      text-align: left;
      font-weight: 500;
    }

    .dividerIcon {
      align-self: center;
      width: 766px;
      height: 1px;
      margin-bottom: 17px;
      margin-left: 3px;
    }

    .sectionTitle {
      flex-shrink: 0;
      align-self: flex-start;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.15;
      color: rgb(0, 3, 20);
      text-align: left;
    }

    .orderInstructionTitle {
      flex-shrink: 0;
      margin-left: -71px;
      font-size: 14px;
      line-height: 1.14;
      color: rgb(7, 14, 50);
      text-align: left;
    }
  }
  &_add {
    position: absolute;
    top: 24px;
    right: 24px;
    color: rgba(25, 56, 237, 1);
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
  }
}

.mt {
  display: flex;
  justify-content: space-between;

  em {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 3, 20, 0.85);
    margin-right: 24px;
  }
  p {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 3, 20, 0.85);
    margin-right: 24px;
  }

  a,
  button {
    color: #006ae0;
    text-decoration: none;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    font-size: 12px;
  }
}

.mc {
  width: 501px;
  height: 94px;
  background: #fff;
  position: relative;

  &_changeadd_text {
    position: absolute;
    color: rgba(25, 56, 237, 1);
    font-size: 14px;
    right: 16px;
    top: 14px;
    cursor: pointer;
  }

  em {
    padding: 0 20px;
    color: #333;
  }

  s,
  b {
    margin-right: 10px;
    padding: 5px;
    text-decoration: none;
    border-radius: 2px;
    color: white;
  }

  s {
    background-color: #ff7c00;
  }

  b {
    background-color: #0d47a0;
  }
}

.mc_background {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mc_box {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  position: absolute;
  top: 3px;
  left: 3px;
  background: #fff;
  z-index: 100;
  padding: 16px;

  &_title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 7px;
    position: relative;
    padding-bottom: 7px;
    display: flex;
    justify-content: left;
    align-items: center;
    span {
      margin-right: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &_receive {
      //display: flex;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 8px;
    }
  }

  &_title::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    bottom: 0;
    border-bottom: 1px dashed rgba(0, 3, 20, 0.15);
  }

  &_content {
    max-width: 400px;
    margin-right: 8px;
    font-size: 14px;
    color: rgba(0, 3, 20, 0.65);
    line-height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 显示的行数，可以根据需要修改 */
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  &_default {
    position: absolute;
    top: -3px;
    left: -3px;
    //width: 51px;
    //height: 17px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: normal;
    color: rgba(255, 255, 255, 1);
    display: inline;
    line-height: 5px;
    //justify-content: center;
    //align-items: center;
    padding: 6px;
    background-color: rgba(1, 1, 1, 0.4);
    opacity: 0.6;
  }
}

.mc:hover {
  .mc_topbottom,
  .mc_leftright {
    background: repeating-linear-gradient(
      -45deg,
      #e00224,
      #e00224 20%,
      transparent 0,
      transparent 25%,
      #00678B 0,
      #00678B 41%,
      transparent 0,
      transparent 50%
    );
  }
}

.mb {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0;

  .on,
  .off {
    position: relative;
    color: #006ae0;
    padding: 0 15px 0 0;
    background-color: transparent;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 2px;
      width: 2px;
      height: 8px;
      background-color: #006ae0;
    }

    &::before {
      right: 0;
    }

    &::after {
      right: 5px;
    }
  }

  .on {
    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }

  .off {
    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    padding: 10px 0;
    color: #333;
  }

  ul {
    max-height: 280px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.item {
  display: flex;
  margin-bottom: 20px;
  padding: 10px;
  width: 410px;
  height: 80px;
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 4px;
  cursor: pointer;

  &:hover,
  &.active {
    background: #f7f7f7;
    border: 1px solid #006ae0;
  }

  &.active {
    .state {
      background-color: #006ae0;

      &::before,
      &::after {
        content: '';
        width: 1px;
        background-color: white;
      }
    }
  }
}

.state {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 10px 2px 0;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: #ccc;

  &::before,
  &::after {
    content: '';
    width: 1px;
    background-color: white;
  }

  &::before {
    margin: 1px 0 0 -1px;
    height: 4px;
    transform: rotate(-50deg);
  }

  &::after {
    margin-left: 2px;
    height: 6px;
    transform: rotate(40deg);
  }
}

.detail {
  flex: 1;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 18px;
  margin-bottom: 10px;
  font-size: 12px;
  color: #333;

  em,
  span {
    font-weight: 600;
  }

  span {
    padding: 0 10px;
  }

  s,
  b {
    margin-right: 10px;
    padding: 3px 5px;
    text-decoration: none;
    border-radius: 2px;
    color: white;
    max-width: 60px;
    text-align: center;
  }

  s {
    background-color: #ff7c00;
  }

  b {
    background-color: #0d47a0;
  }
}

.left {
  display: flex;
  align-items: center;
}

.right {
  button {
    margin-left: 10px;
    padding: 0;
    border: 0;
    background-color: transparent;
    color: #000;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      color: #006ae0;
    }
  }
}

.bottom {
  height: 34px;
  line-height: 17px;
  color: #666;
  overflow: hidden;
}

.empty {
  display: flex;

  i {
    background-image: url(https://img10.360buyimg.com/imagetools/jfs/t1/183721/36/5639/508/60ab4d78E0c20e72a/a9149f6caab7e340.png);
    background-size: cover;
    width: 10px;
    height: 13px;
  }

  em {
    padding: 0 10px;
  }

  a {
    color: #006ae0;
    text-decoration: none;
  }
}

.sm {
  padding: 20px;
}

.smc {
  line-height: 30px;

  p {
    margin: 0;
  }
}

.smb {
  padding: 20px 0 0;
  display: flex;
  justify-content: flex-end;

  button,
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    padding: 0 30px;
    height: 32px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: #000;
    cursor: pointer;
    font-size: 14px;

    &.primary {
      background-color: #0d47a0;
      border-color: #0d47a0;
      color: white;
    }
  }
}

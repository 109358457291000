/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

$contentWidth: 1200px;

html {
  scroll-behavior: smooth;
}

.app {
  width: 100%;
  margin: 0 auto;
  position: relative;


  :global {
    .ant-modal-mask {
      background-color: rgba(0, 0, 0, 0.65);
    }

    .ant-modal-header {
      text-align: center !important;
      padding-bottom: 12px !important;
    }

    .ant-modal-body {
      padding: 0 !important;
    }
    .ant-modal-content {
      padding: 0px;
    }
  }
}

.login {
  width: 100%;
  text-align: center;
  background: #fff;
  position: relative;
  min-width: 1200px;
  overflow: hidden;

  .select_box{
    padding-right: 8px;
    height: 40px;
    font-size: 14px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    margin-right: 10px;
    background: #5d5d5da1;
    background-image: url('../../assets/images/homeImg/open_icon.png') 0 0;
    position: relative;
    color: #fff;
    position: relative;
  }
  .select_option{
    width: 180px;
    position: absolute;
    top: 40px;
    left: 0;
    font-size: 14px;
    cursor: pointer;
    background: #5d5d5da1;
    color: #fff;
  }
  .select_item{
    width: 180px;
    height: 40px;
    display: flex;
    align-items: center;
    text-indent: 25px;
  }
  .circle{
    width: 24px;
    height: 24px;
    margin-left: 10px;
    margin-right: 7px;
  }
  .angel{
    width: 12px;
    height: 12px;
    margin-left: 8px;
  }

  &_addixed_title {
    width: 100%;
    height: 100%;
    background: #fff;
    transition-duration: 50ms;
    text-align: center;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 4px 12px 0 rgba(9, 26, 122, 0.07);

    .title_box {
      width: $contentWidth;
      height: 100%;
      position: relative;
      z-index: 2;

     

      &_name {
        width: 162px;
        font-size: 36px;
        height: 100%;
        color: #fff;
        position: absolute;
        left: 0;
        z-index: 2;
        line-height: 70px;

        img {
          height: 32px;
        }
      }

      &_afix {
        position: absolute;
        right: 410px;
        line-height: 70px;

        a {
          font-size: 16px;
          margin: 0 24px;
          color: #000015;
        }

        a:active,
        a:hover {
          color: #00678B;
        }
      }

      &_more {
        position: absolute;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 320px;
        height: 70px;
        z-index: 2;

        &_scancode {
          font-size: 16px;
          color: #000015;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          img {
            margin-right: 8px;
            width: 16px;
            height: 16px;
          }

          .scancode_QRcode {
            display: none;
          }
        }

        &_scancode:hover {
          .scancode_QRcode {
            display: block;
            width: 120px;
            height: 120px;
            background: rgba(255, 255, 255, 1);
            position: absolute;
            border: 1px solid rgba(238, 238, 238, 1);
            box-shadow: 0 4px 12px 0 rgba(9, 26, 122, 0.1);
            bottom: -130px;
            left: 0;
            //z-index: 100000;

            img {
              width: 100%;
              height: 100%;
              display: inline-block;
            }
          }
        }

        &_telephone {
          background: #00678B;
          width: 151px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          font-size: 15px;
          color: #fff;
          display: flex;
          align-items: center;
          font-family: 'JDZHENGHEI';

          img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            margin-left: 8px;
          }
        }
      }
    }
  }

  &_banner {
    width: 100%;
    height: 540px;
    position: relative;

    :global {
      .ant-modal-body {
        padding: 0 !important;
      }
    }

    &_title {
      width: 100%;
      height: 70px;
      position: absolute;
      top: 0;
      z-index: 1;

      .title_box {
        width: $contentWidth;
        height: 100%;
        display: inline-block;
        position: relative;
        z-index: 2;

        &_name {
          width: 245px;
          font-size: 36px;
          height: 100%;
          color: #fff;
          position: absolute;
          left: 0;
          z-index: 2;
          line-height: 70px;
          font-family: "JDZhengHTBold";
          display: flex;
          align-items: center;
          background-image: var(--header-logo);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100%;
          box-sizing: content-box;
          padding-top: 20px;
          img {
            width: 100%;
          }
        }

        &_more {
          position: absolute;
          right: 0;
          display: flex;
          align-items: center;
          width: 360px;
          height: 70px;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          &_scancode {
            font-size: 16px;
            color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            margin-right: 32px;

            img {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }

            .scancode_QRcode {
              display: none;
            }
          }

          &_scancode:hover {
            .scancode_QRcode {
              display: block;
              width: 120px;
              height: 120px;
              background: rgba(255, 255, 255, 1);
              position: absolute;
              border: 1px solid rgba(238, 238, 238, 1);
              box-shadow: 0 4px 12px 0 rgba(9, 26, 122, 0.1);
              bottom: -130px;
              left: 0;
              z-index: 10;

              img {
                width: 100%;
                height: 100%;
                display: inline-block;
              }
            }
          }

          &_telephone {
            background: rgba(255, 255, 255, 0.15);
            width: 151px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            font-size: 15px;
            color: #fff;
            display: flex;
            align-items: center;
            font-family: 'JDZHENGHEI';

            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
              margin-left: 8px;
            }
          }
        }
      }
    }

    &_title::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      left: 0;
      background: linear-gradient(270deg, rgba(3, 59, 250, 0), rgba(8, 31, 68, 1) 100%);
      filter: blur(10px);
    }

    &_body {
      box-sizing: border-box;
      width: $contentWidth;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      top: 0;
      left: 50%;
      transform: translate(-50%);
      text-align: left;

      .body_slogan {
        width: 700px;

        &_title {
          font-size: 40px;
          color: #fff;
          margin-bottom: 16px;
          font-weight: 600;
        }

        &_subheading {
          width: 560px;
          font-size: 16px;
          color: rgba(255, 255, 255, 0.85);
          margin-bottom: 8px;
          line-height: 24px;
        }
      }

      .body_loginbox {
        width: 340px;
        background: #fff;
        position: relative;

        .welText {
          height: 60px;
          line-height: 60px;
          color: #020208;
          font-size: 16px;
          font-weight: 600;
          width:100%;
          text-align: center;
        }

        .tool {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 15px;
          position: absolute;
          top: 156px;
          right: 1px;

          .forget {
            cursor: pointer;
            font-size: 12px;
            color: #666;
          }
        }

        .tips {
          margin-top: 16px;
          margin-bottom: 24px;
          width: 100%;
          padding: 0 15px;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          &_tip {
            cursor: pointer;
            font-size: 12px;
            color: #00678B;
            display: flex;
            align-items: center;
            position: relative;

            span:hover {
              color: #4F6AF4 !important;
            }

            img {
              width: 12px;
              height: 12px;
              margin-left: 4px;
            }
          }

          &_interval {
            width: 1px;
            height: 10px;
            background: #eee;
            margin: 0 8px;
          }
        }

        .input {
          padding-top: 10px;
          width: 90%;
          margin: 0 auto;
        }
      }
    }
  }

  &_ability {
    display: inline-block;
    width: $contentWidth;
    padding-top: 72px;
    box-sizing: border-box;

    &_bability {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 72px;

      .bability_box {
        width: 370px;
        text-align: center;

        &_img {
          display: inline-block;
          width: 104px;
          height: 104px;
          margin-bottom: 16px;
        }

        &_title {
          width: 100%;
          text-align: center;
          color: #333;
          font-size: 20px;
          margin-bottom: 12px;
          font-weight: 600;
        }

        &_subtitle {
          width: 100%;
          text-align: left;
          font-size: 14px;
          line-height: 24px;
          color: #666;
        }
      }
    }

    &_title {
      font-size: 32px;
      color: #000015;
      width: 100%;
      font-weight: 600;
      margin-bottom: 56px;
      position: relative;

      &_a {
        position: absolute;
        background: red;
        top: -120px;
      }
    }

    &_mability {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 72px;

      .mability_box {
        width: 389px;
        height: 260px;
        background: #F9FAFF;
        margin-bottom: 16px;
        padding: 32px;
        padding-bottom: 28px;
        box-sizing: border-box;
        position: relative;
        text-align: left;
        transition: all 0.1s ease;

        &_img {
          width: 48px;
          height: 48px;
          margin-bottom: 16px;
        }

        &_title {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 12px;
          width: 100%;
          text-align: left;
        }

        &_subtitle {
          font-size: 14px;
          color: #666;
          text-align: left;
          width: 100%;
          line-height: 24px;
        }

        .mability_hover_box {
          left: 0;
          top: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          padding: 32px;
          text-align: left;
          display: none;
          transition: all 0.3s ease;
          background: linear-gradient(135deg, rgba(116, 139, 249, 1) 0%, rgba(25, 56, 237, 1) 100%);

          .hover_box_content {
            font-size: 14px;
            color: #fff;
            box-sizing: border-box;
            position: relative;
            line-height: 24px;
            margin-bottom: 12px;

            i {
              position: absolute;
              width: 4px;
              height: 4px;
              left: 0;
              top: 10px;
              background: #fff;
              margin-right: 8px;
            }

            p {
              margin-left: 8px;
            }
          }
        }
      }

      .mability_box:hover {
        transform: scale(1.1);
        box-shadow: 0 10px 70px 0 rgba(0, 0, 0, 0.1);
        z-index: 3;

        &_img {
          display: none;
        }

        &_title {
          display: none;
        }

        &_subtitle {
          display: none;
        }

        .mability_hover_box {
          display: block;
          z-index: 4;
        }
      }
    }
  }

  &_map {
    display: inline-block;
    width: $contentWidth;
    box-sizing: border-box;

    &_bability {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 72px;

      .bability_box {
        width: 368px;
        text-align: center;

        &_img {
          display: inline-block;
          width: 104px;
          height: 104px;
          margin-bottom: 16px;
        }

        &_title {
          width: 100%;
          text-align: center;
          color: #333;
          font-size: 20px;
          margin-bottom: 12px;
          font-weight: 600;
        }

        &_subtitle {
          width: 100%;
          text-align: left;
          font-size: 14px;
          line-height: 24px;
          color: #666;
        }
      }
    }

    &_title {
      font-size: 32px;
      color: #000015;
      width: 100%;
      font-weight: 600;
      margin-bottom: 56px;
      position: relative;

      &_a {
        position: absolute;
        background: red;
        top: -120px;
      }
    }
  }

  &_carousel {
    padding: 72px 0;
    box-sizing: border-box;
    width: 100%;

    &_title {
      font-size: 32px;
      color: #000015;
      margin-bottom: 56px;
      font-weight: 600;
      position: relative;

      &_a {
        position: absolute;
        top: -120px;
      }
    }

    &_body {
      display: inline-block;
      width: $contentWidth;
      height: 338px;
      position: relative;

      .carousel_tabs {
        width: 242px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;

        &_tab {
          width: calc(100% - 18px);
          height: 84.5px;
          text-align: left;
          padding-left: 45px;
          box-sizing: border-box;
          color: #333;
          font-size: 16px;
          font-weight: 600;
          line-height: 85px;
          background: #fff;
          transition: all 0.1s ease 0.1s;
        }

        &_tab_active {
          width: 100%;
          background: #00678B !important;
          color: #fff !important;
          position: relative;
          transition: all 0.1s ease 0.1s;

          i {
            position: absolute;
            right: 16px;
            top: 36px;
            border: 6px solid #fff;
            border-color: transparent transparent transparent #fff;
          }
        }
      }

      .carousel_content {
        width: 968px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        background: #fff;

        .content_showbox {
          width: 100%;
          height: 100%;
          position: relative;
          overflow: hidden;

          .content_showbox_inner {
            width: 100%;
            height: 338px;
            background: #fff;
            padding: 32px;
            top: 0;
            position: absolute;
            transition-duration: 550ms;
            opacity: 0;

            .inner_1 {
              width: 100%;
              height: 100%;
              text-align: left;
              display: flex;
              justify-content: space-between;

              &_left {
                width: 422px;

                .left_title {
                  font-size: 18px;
                  color: #333;
                  font-weight: 600;
                  margin-bottom: 12px;
                }

                .left_down {
                  color: #666;
                  font-size: 14px;
                  margin-bottom: 6px;
                  line-height: 24px;

                  span {
                    font-weight: 600;
                  }
                }

                .left_more {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  margin-top: 32px;

                  .left_down_in_chile1 {
                    color: #333;
                    font-size: 24px;
                    margin-bottom: 8px;
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    font-family: "JDZHENGHEI";
                  }

                  .left_down_in_chile2 {
                    color: #999;
                    text-align: left;
                    font-size: 14px;
                  }
                }
              }

              img {
                width: 425px;
                height: 274px;
              }
            }

            .inner_common {
              width: 100%;
              height: 100%;
              box-sizing: border-box;
              display: flex;
              justify-content: space-between;
              text-align: left;

              .common_content {
                width: 429px;

                &_text {
                  font-size: 14px;
                  color: #666;
                  margin-bottom: 22px;
                  line-height: 24px;

                  p:first-child {
                    font-size: 18px;
                    color: #333;
                    margin-bottom: 6px;
                    font-weight: 600;
                  }
                }
              }

              img {
                width: 425px;
                height: 274px;
              }
            }
          }
        }
      }
    }
  }

  &_serve {
    padding-top: 72px;
    display: inline-block;
    width: $contentWidth;

    &_title {
      color: #000015;
      font-size: 32px;
      margin-bottom: 56px;
      font-weight: 600;
      position: relative;

      &_a {
        position: absolute;
        top: -120px;
      }
    }

    &_body {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 56px;

      .serve_box {
        width: 592px;
        height: 202px;
        background: #f8f9ff;
        margin-bottom: 16px;
        box-sizing: border-box;
        display: flex;
        transition-duration: 0.2s;

        &_img {
          width: 110px;
          height: 110px;
          display: block;
          margin-top: 46px;
          margin-left: 24px;
          margin-right: 24px;
        }

        &_right {
          text-align: left;
          padding-top: 36px;
          width: 100%;

          &_number {
            width: 50px;
            height: 38px;
            transition-duration: 0.5s;
            margin-bottom: 17px;
          }

          &_title {
            width: 100%;
            font-size: 20px;
            color: #333;
            margin-bottom: 12px;
            font-weight: 600;
            transition-duration: 0.5s;
          }

          &_subtitle {
            color: #666;
            width: 402px;
            line-height: 24px;
            font-size: 14px;
            transition-duration: 0.5s;
            height: 50px;

            .serve_box_right_subtitle2 {
              opacity: 0;
            }
          }
        }
      }

      .serve_box:hover {
        transform: translateY(-5%);
        transition-duration: 0.5s;

        .serve_box_right_number {
          opacity: 0;
          transition-duration: 0.5s;
        }

        .serve_box_right_title {
          transform: translateY(-180%);
        }

        .serve_box_right_subtitle {
          transition-duration: 0.5s;
          height: 72px;
          transform: translateY(-56%);

          .serve_box_right_subtitle2 {
            opacity: 1;
            transition-duration: 0.5s;
            //transform: translateY(-42%);
          }
        }
      }
    }
  }

  &_case {
    width: 100%;
    display: inline-block;
    padding-top: 72px;
    padding-bottom: 52px;
    box-sizing: border-box;
    background: #f8f9ff;

    &_title {
      font-size: 32px;
      color: #000015;
      margin-bottom: 56px;
      font-weight: 500;
      position: relative;

      &_a {
        position: absolute;
        top: -120px;
      }
    }

    &_cases {
      display: inline-block;

      .cases_boxs {
        width: $contentWidth;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .boxs_box {
          width: 227px;
          height: 88px;
          background: #fff;
          margin-bottom: 20px;

          .box_img {
            width: 100%;
            height: 100%;
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            width: 389px;
            height: 192px;
            padding: 32px;
            position: relative;

            .box_img {
              width: 136px;
              height: 40px;
              display: block;
              margin-bottom: 16px;
            }

            .box_title {
              width: 100%;
              text-align: left;
              color: #666;
              font-size: 14px;
              line-height: 20px;
            }

            i {
              width: 44px;
              height: 40px;
              display: block;
              position: absolute;
              right: 32px;
              top: 32px;
            }
          }
        }
      }
    }
  }

  &_registered {
    width: 100%;
    height: 173px;
    padding-top: 48px;
    box-sizing: border-box;

    &_title {
      color: #fff;
      font-size: 32px;
      font-weight: 500;
      margin-bottom: 24px;
      position: relative;
      z-index: 10;
    }

    &_qregistered {
      position: relative;
      z-index: 10;
      display: inline-block;
      border: 1px solid rgba(255, 255, 255, 0.45);
      padding: 6px 16px;
      font-size: 14px;
      color: #fff;
    }

    &_qregistered:hover {
      background: rgba(255, 255, 255, 1) !important;
      color: #00678B !important;
    }
  }

  &_foot {
    width: 100%;
    height: 173px;
    background: #3d4352;
    position: relative;
    z-index: 10;

    &_body {
      display: inline-block;
      width: $contentWidth;

      .body_box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 41px;
        box-sizing: border-box;

        .box_left {
          text-align: left;

          &_title {
            position: relative;
            margin-bottom: 24px;
            z-index: 10;

            img {
              width: 112px;
              height: 24px;
            }
          }

          &_comps {
            display: flex;
            margin-bottom: 12px;

            li {
              margin-right: 24px;
              color: #fff;
              position: relative;
              z-index: 10;

              a {
                color: #fff;
                font-size: 14px;
                opacity: 0.85;
              }
            }
          }
          &_control{
            display: flex;
            height: 17px;
            line-height: 17px;
            margin-bottom: 8px;
            font-size: 12px;
            color: rgba(255,255,255,0.5);
            li {
              margin-right: 16px;
              position: relative;
              &::after{
               position: absolute;
               top: 4px;
               right: -8px;
               content: "";
               width: 1px;
               height: 10px;
               background: #565B69;
              }
              &:last-child{
                &::after{
                  width: 0;
                }
              }

              a {
                color: rgba(255, 255, 255, 0.5);
                &:hover{
                  color: rgba(255,255,255,1);
                }
              }

            }
          }


          &_copyright {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.45);
            position: relative;
            z-index: 10;
          }
        }

        .login_foot_QRcode {
          color: rgba(255,255,255,0.45);
          font-size: 12px;
          img {
            width: 72px;
            height: 72px;
            margin-bottom: 8px;
          }
        }


      }
    }
  }

  .bottom_background_box {
    position: relative;
    overflow: hidden;
  }

  .mouse_hover_heightlight {
    width: 840px;
    height: 840px;
    position: absolute;
    top: -420px;
    left: -420px;
    display: block;
    transition-duration: 0.5s;
  }
}

@keyframes run {
  0% {
    left: -389px;
    top: 232px;
  }

  33% {
    left: -289px;
    top: 132px;
  }

  66% {
    left: -189px;
    top: 32px;
  }

  100% {
    left: 0;
    top: 0;
  }
}

/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.m {
  margin: 0 auto;
  font-size: 14px;
  padding-top: 27px;
  padding-bottom: 50px;
  :global {
    .ant-modal-close-x {
      width: 40px;
      height: 40px;
      line-height: 40px;
    }
    .ant-modal-header {
      padding: 0 20px;
      height: 40px;
      display: flex;
      align-items: center;
      background: #f7f7f7;
    }
    .ant-modal-title {
      color: black;
      font-size: 16px;
      font-weight: 600;
    }
    .ant-modal-body {
      //padding: 0;
      word-wrap: break-word;
      font-size: 14px;
      line-height: 1.5715;
    }
    .ant-message-notice-content {
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 0.5rem;
      color: white;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .toast.ani-upin,
    .toast.ani-upout {
      font-size: 14px;
      line-height: 1.5;
    }
  }

  &_paysuccess {
    width: 100%;
    height: calc(100vh - 64px - 120px);
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &_box {
      text-align: center;

      img {
        width: 82px;
      }
      p {
        color: #000000;
        font-size: 20px;
        line-height: 33px;
      }

      .btns {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
      }
      .box_buttongoulp {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
      }
      .m_paysuccess_button {
        cursor: pointer;
        margin-bottom: 8px;
        width: 248px;
        box-sizing: border-box;
        border: 1px solid rgba(7, 14, 50, 0.4);
        border-radius: 4px;
        background: #ffffff;

        a {
          display: inline-block;
          width: 200px;
          line-height: 48px;
          color: #070e32 !important;
        }
      }

      .m_paysuccess_button:first-child {
        width: 248px;
        box-sizing: border-box;
        border: 2px solid #00678b;
        border-radius: 4px;
        background: #ffffff;

        a {
          color: #00678b !important;
        }
      }
    }
  }
}
.mt {
  font-size: 16px;
  height: 22px;
  color: #333;
  margin-bottom: 20px;
}
.mc {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.middlepage {
  width: 1200px;
  height: 725px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  &_box {
    &_pic {
      width: 327px;
      height: 275px;
      margin-bottom: 8px;
    }

    &_title {
      font-size: 28px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 10px;
      text-align: center;
    }

    &_content {
      font-size: 16px;
      font-weight: normal;
      color: rgba(102, 102, 102, 1);
      margin-bottom: 32px;
      text-align: center;
    }

    &_buttons {
      text-align: center;

      .button_act,
      .button_normal {
        width: 140px;
        height: 48px;
        border: 1px solid rgba(217, 217, 217, 1);
        border-radius: 5px;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        font-size: 16px;
        text-align: center;
        line-height: 46px;
        display: inline-block;
        cursor: pointer;
      }

      .button_act {
        background: rgba(13, 71, 160, 1);
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
.priceInfo {
  width: 34%;
  padding-left: 12px;
  margin-top: 60px;
}

.main {
  width: 66%;
  .mainHeaderTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 0;
    .mainHeaderText {
      flex-shrink: 0;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.17;
      color: rgb(0, 3, 20);
      text-align: left;
    }
  }
  &_title {
    font-weight: 600;
    color: rgba(0, 3, 20, 0.85);
    padding: 24px 0 16px 24px;
    font-size: 20px;
  }

  &_box {
    background: #fff;
    margin-bottom: 16px;
  }

  &_more {
    background: #fff;
    margin-bottom: 8px;
  }

  &_remark {
    margin: 16px 0;
    height: 138px;
    padding: 24px;
    background: #fff;
  }
}
.fixed {
  position: sticky;
  bottom: 0;
  z-index: 99;
}
.abs {
  position: absolute;
  bottom: 0;
  z-index: 99;
}

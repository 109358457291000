/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.conent {
  display: flex;
  justify-content: space-between;
  background: #fff;
  //box-shadow: 0 -2px 8px 1px rgba(0, 0, 0, 0.05);

  a {
    text-decoration: none;
  }

  a,
  input {
    outline: none;
  }

  .page_left {
    display: flex;

    .ps_title {
      display: flex;
      width: 40px;
      justify-content: space-between;
      align-items: center;
      margin-left: 20px;

      span {
        color: #999;
      }
    }

    .view_sortgroup {
      // margin-left: 10px;
      display: flex;
      align-items: center;
    }

    .view_sort {
      display: inline-block;
      vertical-align: top;
      cursor: pointer;

      >a {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        padding: 0 16px 0 14px;
        text-align: center;
        cursor: pointer;
        // width: 124px;
        // border: 1px solid #ccc;
        border-right: 1px solid #ededed;
        // border-left: 1px solid #ededed;
        margin-right: -1px;
        color: #666;
        text-decoration: none;

        &.curr {
          border-color: #0d47a0;
          background: rgba(25, 56, 237, 1);
          color: #fff;
          padding: 0 16px 0 14px;

          .arrow {
            background:
              url("/assets/images/searchList/xiaoliang_act.png") 0 0 / 100% 100%;
          }
        }

        &.curr1 {
          text-align: center;
          // width: 124px;
          padding: 0 16px 0 14px;
          height: 40px;
          background: rgba(25, 56, 237, 1);
          color: #fff;
          margin-right: 0;

          .arrow_top {
            background:
              url("/assets/images/searchList/xiaoliang_act.png") 0 0 / 100% 100%;
          }

          .arrow_bottom {
            opacity: 0.3;
          }
        }

        &.curr2 {
          // border-color: #0d47a0;
          background: rgba(25, 56, 237, 1);
          color: #fff;
          padding: 0 16px 0 14px;

          .arrow_top {
            opacity: 0.3;
          }

          .arrow_bottom {
            background:
              url("/assets/images/searchList/xiaoliang_act.png") 0 0 / 100% 100%;
          }
        }

        .arrow {
          width: 6px;
          height: 12px;
          display: inline-block;
          background: url("/assets/images/searchList/xiaoliang.png") 0 0 / 100% 100%;
          margin-left: 8px;
          vertical-align: -2px;
        }

        .fs_down {
          display: inline-block;
          width: 7px;
          margin-left: 6px;
          height: 40px;
          vertical-align: top;
          position: relative;
        }
      }
    }

    .comprehensive {
      >a {
        border-right: 0px;
      }
    }

    .view_price {
      display: inline-block;
      position: relative;
      // height: 24px;
      margin: 0px 5px 0 15px;
      color: #666;

      .title {
        width: 100px;
        display: inline-block;
      }

      &:hover {

        // z-index: 6;
        .price_set {
          position: relative;
          z-index: 5;
        }

        .price_edit {
          display: flex;
          top: -7px;
          left: -7px;
        }
      }

      .price_set {
        // width: 100%;
        // height: 100%;
        overflow: hidden;

        .fl {
          width: 86px;
          display: inline-block;
          border: 1px solid #ccc;
          height: 28px;
          line-height: 28px;

          >span {
            display: inline-block;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
            padding-left: 8px;
          }
        }

        em {
          display: inline-block;
          width: 12px;
          vertical-align: 1px;
          color: #333;
          text-align: center;
        }
        p{
          display: inline-block;
          width: 12px;
          vertical-align: 1px;
          color: #666;
          text-align: center;
          margin: 0 2px;
        }
      }

      .price_edit {
        display: none;
        width: 192px;
        padding: 47px 6px 10px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
        position: absolute;
        z-index: 4;
        background: #fff;
        justify-content: space-between;

        .btn1 {
          width: 36px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          color: #00678B;
        }

        .btn2 {
          width: 66px;
          height: 22px;
          background: rgba(255, 255, 255, 0.45);
          border: 1px solid rgba(25, 56, 237, 1);
          line-height: 22px;
          text-align: center;
          color: #00678B;
          border-radius: 2px;
        }
      }
    }

    .view_address {
      position: relative;
      width: 130px;
      height: 24px;
      margin: 8px 0 0 10px;
      color: #666;

      .title {
        width: 100px;
        display: inline-block;
      }

      &:hover {

        // z-index: 6;
        .price_set {
          position: relative;
          z-index: 5;
        }

        .price_edit {
          display: flex;
          top: -7px;
          left: -7px;
        }
      }

      .price_set {
        // width: 100%;
        // height: 100%;
        overflow: hidden;

        .fl {
          display: inline-block;
          border: 1px solid #ccc;
          height: 25px;
          line-height: 24px;

          >span {
            display: inline-block;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
            padding-left: 8px;
          }
        }

        em {
          display: inline-block;
          width: 12px;
          vertical-align: 1px;
          color: #333;
          text-align: center;
        }

      }

      .price_edit {
        display: none;
        width: 135px;
        padding: 37px 6px 6px;
        border: 1px solid #EDEDED;
        position: absolute;
        z-index: 4;
        background: #fff;
        justify-content: space-between;

        .btn1 {
          // position: absolute;
          // top: 37px;
          // left: 6px;
          width: 36px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          color: #006ae0;
        }

        .btn2 {
          // position: absolute;
          // top: 37px;
          // right: 7px;
          width: 54px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          color: #333;
          background: rgba(0, 0, 0, 0.04);
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 2px;
        }
      }
    }

    .view_inventory {
      display: flex;
      align-items: center;
      margin-left: 10px;

      .in_label {
        cursor: pointer;
        color: #666;

        .icon {
          width: 14px;
          height: 14px;
          display: inline-block;
          border: 1px solid rgba(204, 204, 204, 1);
          vertical-align: sub;
          margin-right: 6px;

          &:hover {
            border: 1px solid #205fc7;
          }
        }

        input {
          opacity: 0;
        }

        input[type="checkbox"]:checked+i {
          border: none;
          background: url("/assets/images/searchList/checked.png") 0 0 / 100% 100%;
        }
      }
    }

    .border_left {
      padding-left: 20px;
      border-left: 1px solid #ededed;
    }

    .border_left_small {
      padding-left: 6px;
      border-left: 1px solid #ededed;
    }

    .hide {
      display: none;
    }

    .sort_price {
      >a {
        // width: 66px;
        padding: 0 16px 0 14px;

        .fs_down {
          margin-left: 0px;
        }

        &.curr1 {
          width: 68px;
        }
      }
    }
  }

  .page_right {
    display: flex;
    align-items: center;

    .view_type {
      display: flex;
      width: 160px;
      justify-content: space-between;
      align-items: center;
      border-left: 1px solid #ededed;
      padding-left: 20px;
      border-right: 1px solid #ededed;
      padding-right: 20px;

      >span {
        width: 50px;
        position: relative;
        cursor: pointer;
        color: #666;
        padding-left: 8px;

        &.act {
          color: #0d47a0;

          // &::before {
          //   content: "";
          //   position: absolute;
          //   bottom: -12px;
          //   left: 0;
          //   width: 50px;
          //   height: 2px;
          //   background: #0d47a0;
          //   border-radius: 5px;
          // }
          &:nth-child(1) {
            >i {
              background:
                url("/assets/images/searchList/list_act.png") 0 0 / 100% 100%;
            }
          }

          &:nth-child(2) {
            >i {
              background: url("https://s3-relay.360buyimg.com/relay/cache/cut/081b7621cf5e7f4729d9e1738c658beb") 0 0 / 100% 100%;
            }
          }
        }

        >i {
          width: 15px;
          height: 15px;
          display: inline-block;
          vertical-align: sub;
          margin-right: 6px;
        }
      }

      >span:nth-child(1) {
        >i {
          background: url("https://s4-relay.360buyimg.com/relay/cache/cut/9879caf98abf092bd8eaf3f42058c982") 0 0 / 100% 100%;
        }
      }

      >span:nth-child(2) {
        >i {
          background: url("/assets/images/searchList/big.png") 0 0 / 100% 100%;
        }
      }
    }

    .view_type_small {
      display: flex;
      width: 130px;
      justify-content: space-between;
      align-items: center;
      border-left: 1px solid #ededed;
      padding-left: 12px;
      border-right: 1px solid #ededed;
      padding-right: 8px;
    }

    .cumulative {
      font-size: 14px;
      color: #999;
      // margin-right: 20px;
      font-weight: normal;

      span {
        color: #666;
      }
    }

    .goods_page {
      display: flex;
      align-items: center;
      margin-right: 20px;
      margin-left: 16px;

      .page_l {
        width: 20px;
        height: 20px;
        line-height: 25px;
        text-align: center;
        border-radius: 10px;
        cursor: pointer;

        >i {
          width: 14px;
          height: 14px;
          display: inline-block;
          background: url("../../../assets/images/international/searchList/activeLeft_searchList_icon.png") 0 0 / 100% 100%;
        }
      }

      .page_r {
        width: 20px;
        height: 20px;
        line-height: 25px;
        text-align: center;
        border-radius: 10px;
        cursor: pointer;
        >i {
          width: 14px;
          height: 14px;
          display: inline-block;
          background: url("https://jdios.jdindustry.com/mubla1/22617367635771849.png") 0 0 / 100% 100%;
        }
      }

      .disablePage {
        cursor: default !important;

        .page_r {
          >i {
            width: 14px;
            height: 14px;
            display: inline-block;
            transform: rotate(180deg);
            background: url("https://jdios.jdindustry.com/mubla1/22617367635771849.png") 0 0 / auto auto;
            background-position: center;
            background-repeat: no-repeat;
          }
        }

        .page_l {
          >i {
            width: 14px;
            height: 14px;
            display: inline-block;
            background: url("https://jdios.jdindustry.com/mubla1/22617367635771849.png") 0 0 / auto auto;
            background-position: center;
            background-repeat: no-repeat;
          }
        }

      }

      .page_con {
        padding: 0 5px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        cursor: default;
        color: #00678B;        
      }
    }

    .goods_page_small {
      margin-right: 15px;
    }

    .go_shop {
      display: flex;

      .select_num {
        min-width: 90px;
        line-height: 40px;
        font-size: 12px;
        font-weight: 500;
        color: rgba(13, 71, 160, 1);
        text-align: right;
        margin-right: 12px;
        font-weight: 500;
      }

      .add_btn {
        // width: 120px;
        height: 40px;
        line-height: 40px;
        background: #0d47a0;
        color: #fff;
        font-size: 12px;
        padding: 0 10px;
        cursor: pointer;
        text-align: center;

        &.dis {
          cursor: default;
          background: rgba(229, 229, 229, 1);
        }

        span {
          max-width: 100px;
          height: 40px;
          overflow: hidden;
        }
      }
    }
  }

  :global {
    .screening-number .screening-number-input-wrap input {
      width: 60px;
    }
  }
}

#jdarea .ui-switchable-item em {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.filter_dropdown {
  margin-right: 15px;
  display: inline-block;
  vertical-align: middle;
}

.filter_dropdown_menu {
  :global(.ant-dropdown-menu-item:hover) {
    background-color: unset!important;
    text-decoration: underline;
    color: #00678B;
  }
}
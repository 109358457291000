/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.content {
  //width: 940px;
  font-size: 12px;
  position: relative;
  .larger_img {
    margin: 0 auto;
    padding: 0 0 10px 0;
    overflow: hidden;
    position: relative;
    .gl_warp {
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      padding-top: 22px;
      overflow: hidden;
      .gl_item {
        padding: 1px 14px 20px 14px;
        margin-right: 8px;
        margin-bottom: 8px;
        box-sizing: border-box;
        position: relative;
        width: 24.3%;
        background-color: rgb(255, 255, 255);
        border: 1px solid #f5f5f5;
        border-radius: 6px;
        // padding-bottom: 20px;
        &:hover {
          padding: 0 13px 19px 13px;
          border-color: rgba(0, 103, 139, 0.25);
          border-width: 2px;
        }
        & {
          // transform: translate3d(0, 0px, 0);
          // transition: all 0.2s linear;
        }
        img {
          cursor: pointer;
        }
        .title {
          width: 210px;
          padding-left: 12px;
          line-height: 17px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          color: #333;

          &:hover {
            > a {
              color: #0d47a0;
            }
          }

          > a {
            font-size: 12px;
            color: #333;
          }
        }
        .title_1 {
          color: #00678b;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0px;
          font-weight: 500;
          margin: 12px 0;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          width: 100%;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }

        .productCode {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          .productCodeLabel {
            flex-shrink: 0;
            font-size: 12px;
            line-height: 1;
            color: rgba(7, 14, 50, 0.5);
          }
          .productCodeValue {
            flex-shrink: 0;
            margin-left: 5px;
            font-size: 12px;
            line-height: 1;
            color: rgb(7, 14, 50);
          }
        }

        .order_Num,
        .model_Num {
          font-size: 12px;
          line-height: 17px;
          color: rgba(0, 0, 0, 0.65);
          // margin-top: 2px;
          padding-left: 12px;
          cursor: default;
        }
        .text_space {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .noPrice {
          color: #070e32;
          font-size: 20px;
          line-height: 20px;
          margin: 16px 0;
        }
        .productTag {
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;
          padding: 2px;
          background-color: rgb(255, 255, 255);
          border: 0.5px solid rgba(7, 14, 50, 0.4);
          border-width: 1px;
          border-radius: 3px;
          .tagIcon {
            width: 10px;
            height: 10px;
          }
          .tagSection {
            flex-shrink: 0;
            margin-left: 2px;
            font-size: 12px;
            line-height: 1;
            color: rgba(7, 14, 50, 0.5);
          }
        }
        .ent_price {
          margin: 4px 0 16px;
          font-size: 20px;
          font-weight: 600;
          color: #e50c00;
          // height: 20px;
          font-weight: normal;
          cursor: default;
          width: 100%;
          .contain_price {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            align-items: flex-end;
            .price_wrap {
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              color: rgb(225, 37, 27);
              margin-right: 5px;
              margin-top: 12px;
              .dollar {
                flex-shrink: 0;
                font-size: 14px;
                text-align: left;
              }
              .price {
                flex-shrink: 0;
                margin-left: 1px;
                font-size: 20px;
                position: relative;
                top: 1px;
                flex: 1;
                word-break: break-all;
              }
            }
          }
          .label {
            color: rgba(7, 14, 50, 0.5);
            font-size: 12px;
            margin-top: 5px;
          }
          em {
            font-size: 16px;
            font-style: normal;
            margin-right: 2px;
            font-weight: bold;
          }
          .jd_price {
            //margin: 0 0 2px 12px;
            line-height: 22px;
            font-size: 12px;
            color: #999;
            cursor: default;
            float: right;
            padding-right: 8px;
            font-weight: normal;
          }
        }

        .tags {
          height: 20px;
          margin: 6px 0 12px 8px;
          display: flex;
          justify-content: left;
          cursor: default;

          span {
            display: inline-block;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: normal;
            color: rgba(229, 12, 0, 1);
            border: 1px solid #f6aaa6;
            margin-left: 4px;
            padding: 4px;
            text-align: center;
            &.biaopin {
              color: #ffffff;
              border: none;
              background: linear-gradient(90deg, rgba(111, 131, 242, 1) 0%, rgba(67, 93, 240, 1) 100%);
            }
            &.ziying {
              color: #ffffff;
              border: none;
              background: rgba(229, 12, 0, 1);
            }
          }
        }

        .operations {
          margin-top: 20px;
        }
        .operationBtn {
          display: flex;
          width: fit-content;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          background-color: rgb(255, 255, 255);
          border: 1px solid rgba(7, 14, 50, 0.3);
          border-radius: 26px;
          padding: 8px 13px;
          margin-bottom: 10px;
          cursor: pointer;
          transition: all 0.2s ease;
          .operationBtnContainer {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .operationInstruction {
              font-size: 12px;
              color: rgb(0, 103, 139);
            }
            .cartImage {
              width: 15px;
              height: 15px;
              margin-right: 4px;
              @include background-image-cdn('mubla1/195355297192308675.png');
            }
          }
          &:hover {
            background-color: rgb(0, 103, 139);
            border: 1px solid rgb(0, 103, 139);
            .operationBtnContainer {
              .operationInstruction {
                color: #fff;
              }
              .cartImage {
                width: 15px;
                height: 15px;
                @include background-image-cdn('mubla1/22873479176368173.png');
              }
            }
          }
        }

        .operation {
          margin-top: 12px;
          padding-left: 12px;
          border-top: 1px solid #ededed;
          display: flex;
          align-items: center;
          justify-content: center;

          .add_shop {
            display: inline-block;
            width: 148px;
            height: 42px;
            line-height: 42px;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            color: rgba(25, 56, 237, 1);
            vertical-align: top;
            cursor: pointer;
            i {
              vertical-align: sub;
              width: 14px;
              height: 14px;
              margin-right: 6px;
              display: inline-block;
              background: url('../../../../assets/images/international/searchList/car_search_icon.png') 0 0 / 100% 100%;
            }
            // &:hover {
            //   background-color: rgba(13, 71, 160, 1);
            //   color: #fff;
            // }
          }
          .replenishment {
            display: inline-block;
            width: 148px;
            height: 42px;
            line-height: 42px;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            color: rgba(25, 56, 237, 1);
            vertical-align: top;
            cursor: pointer;
            i {
              vertical-align: sub;
              width: 14px;
              height: 14px;
              margin-right: 6px;
              display: inline-block;
              background: url('../../../../assets/images/searchList/replenishment.png') 0 0 / 100% 100%;
            }
          }
        }

        .stock {
          position: absolute;
          display: flex;
          flex-direction: row;
          align-items: center;
          align-self: flex-end;
          height: 22px;
          padding-left: 10px;
          padding-right: 20px;
          left: 0;
          bottom: 14px;
          z-index: 10;
          @include half-pixel-border(#fff, all, 0, 22px, 22px, 0);
          background: rgba(17, 31, 54, 0.7);
          color: #fff;
          .remainNum {
            color: #ffd000;
            margin: 0 3px;
          }
        }
        .deliveryDate {
          position: absolute;
          top: 208px;
          height: 24px;
          line-height: 24px;
          width: 100%;
          overflow: hidden;
          padding-left: 8px;
          color: #fff;
          background: rgba(0, 0, 0, 0.45);
          font-size: 12px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          &.stock_surplus100 {
            background: rgba(237, 85, 2, 0.65);
          }
        }
      }
      @media screen and (min-width: 1296px) {
        .gl_item {
          width: 19.4%; /* 当屏幕宽度大于等于 1024px 时，宽度变为 19% */
          &:nth-child(5n + 5) {
            margin-right: 0;
          }
        }
      }
      @media screen and (max-width: 1296px) {
        .gl_item {
          &:nth-child(4n + 4) {
            margin-right: 0;
          }
        }
      }
    }
    .pagination {
      text-align: right;
      width: 920px;
      margin: 32px 0px 72px 0px;
      width: 100%;
    }
  }
}

.stock_surplus100 {
  // 原有样式保持不变...
}

.stock_purchasing {
  color: #ff9500;          // 采购中状态使用橙色
  background: #fff9e6;     // 浅橙色背景
  border: 1px solid #ffe7ba; // 橙色边框
}

.tagsContainer {
  display: flex;
  flex-direction: row;
}

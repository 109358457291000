/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.inputNumberPanel {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  :global {
    .ant-input-number-wrapper,
    .ant-input-number-input-wrap,
    .ant-input-number-input {
      height: 100%;
      text-align: center;
    }
    .ant-input-number-input {
      text-align: center;
      padding: 4px 5px;
      font-size: 14px;
    }
    // .ant-input-number-outlined:hover {
    //   border: none!important;
    //   box-shadow: none!important;
    // }
  }
  @keyframes hideTipsAnimation {
    0% {
      display: block;
    }
    99% {
      display: block;
    }
    100% {
      display: none;
    }
  }
  :global {
    .cart-number {
      border: 1px solid #c9cdd4;
      width: 136px;
      // margin-left: -24px;
    }

    .cart-number-handler {
      width: 32px;
      height: 32px;
    }
    .cart-number-handler-up {
      border-left: 1px solid #c9cdd4;
    }
    .cart-number-handler-down {
      border-right: 1px solid #c9cdd4;
    }

    .cart-number-input-wrap {
      padding: 0 30px;
    }

    .cart-number-input-wrap input {
      width: 72px;
      height: 32px;
    }
    input:disabled {
      background: #f5f6fa !important;
    }
  }
  .tips {
    position: absolute;
    left: 50%;
    top: -38px;
    transform: translate(-50%, 0px);
    padding: 0 10px;
    width: auto;
    height: 32px;
    line-height: 32px;
    background: #ffffff;
    border-radius: 4px;
    color: #262937;
    z-index: 999;
    white-space: nowrap;
    box-shadow: 0px 8px 24px 8px rgba(31, 35, 41, 0.04), 0px 6px 12px 0px rgba(31, 35, 41, 0.04),
      0px 4px 8px -8px rgba(31, 35, 41, 0.06);
    .warn {
      color: #ff8b1b;
      font-size: 16px;
      margin-right: 5px;
    }
    &::before {
      content: '';
      width: 0;
      height: 0;
      border-width: 5px 5px 0;
      border-style: solid;
      border-color: #ffffff transparent transparent;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0px);
      bottom: -4px;
    }
  }
  .showTips {
    //  animation: hideTips 0.3s;
    -moz-animation: hideTipsAnimation 0s ease-in 5s forwards;
    /* Firefox */
    -webkit-animation: hideTipsAnimation 0s ease-in 5s forwards;
    /* Safari and Chrome */
    -o-animation: hideTipsAnimation 0s ease-in 5s forwards;
    /* Opera */
    animation: hideTipsAnimation 0s ease-in 5s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .message {
    font-size: 12px;
    color: #ff8b1b;
    line-height: 20px;
    margin-top: 5px;
    text-align: center;
  }
}

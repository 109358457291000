/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.products {
  background-color: #fff;
  font-size: 12px;
  // min-height:520px;
  .questionTip {
    background-color: rgba(7, 14, 50, 0.05);
    border-radius: 4px;
    padding: 12px 20px;
    color: #070E32;
    font-size: 14px;
    line-height: 18px;
  }
  .datePicker {
    inset: unset !important;
    bottom: 22px !important;
    left: 24px !important;
  }
  .orderItemContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    font-family: Arial;
    font-weight: 400;
    color: rgb(7, 14, 50);
    margin-bottom: 20px;
    margin-top: 8px;
  }

  .orderContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 1px solid #e3e3e7;
    border-top: none;
    border-radius: 0 0 4px 4px;
  }

  .productDetailsBox {
    width: 70%;
    flex-shrink: 0;
  }

  .productDetails {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border-left: 1px solid rgba(7, 14, 50, 0.1);
    border-bottom: 1px solid rgba(7, 14, 50, 0.1);
    padding: 16px 0;
    border-bottom-right-radius: 4px;
    &:last-child {
      border-bottom: none;
    }
  }

  .subtotalInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    align-self: flex-start;
    width: 21.5%;
    padding-right: 24px;
  }

  .subtotalRow {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 79px;
    height: 30px;
  }

  .subtotalRow:last-child {
    margin-top: 6px;
  }

  .subtotalValue {
    flex-shrink: 0;
    font-size: 14px;
    line-height: 1.14;
    color: rgb(225, 37, 27);
    text-align: right;
  }

  .subtotalLabel {
    flex-shrink: 0;
    font-size: 12px;
    line-height: 1.17;
    color: #070e32;
    text-align: left;
    padding-right: 24px;
  }

  .quantity {
    flex-shrink: 0;
    align-self: flex-start;
    font-size: 14px;
    line-height: 1.14;
    color: rgb(7, 14, 50);
    text-align: center;
    width: 21.5%;
  }

  .productInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    margin-left: 12px;
    min-width: 0;
    flex: 1;
  }

  .customsClearance {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 12px;
  }

  .customsText {
    flex-shrink: 0;
    margin-left: 12px;
    font-size: 13px;
    line-height: 1.15;
    color: rgb(7, 14, 50);
    text-align: left;
  }

  .customsIcon {
    overflow: hidden;
    width: 28px;
    height: 16px;
  }

  .productMetadata {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 4px;
    width: 100%;
    > div {
      margin-top: 6px;
    }
  }

  .metadataLabel {
    flex-shrink: 0;
    font-size: 12px;
    line-height: 1.17;
    color: rgba(7, 14, 50, 0.5);
    text-align: left;
  }

  .productName {
    flex-shrink: 0;
    font-size: 14px;
    line-height: 18px;
    color: rgb(7, 14, 50);
    text-align: left;
  }

  .productInfoBox {
    flex: 1;
    min-width: 0;
    padding-left: 21px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .productImage {
    align-self: flex-start;
    width: 36px;
    height: 36px;
    border: 1px solid #dfe1e4;
    border-radius: 2px;
  }

  .deliveryMethodWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    box-sizing: border-box;
    width: 30%;
    background-color: rgb(251, 251, 251);
    padding: 16px 24px;
    border-bottom-left-radius: 4px;
  }

  .deliveryMethodContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
  }

  .deliveryOptions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 12px;
    position: relative;
  }

  .time {
    margin-top: 16px;
    line-height: 22px;
    .timesTitle {
      word-break: break-all;
      flex-shrink: 0;
      font-size: 14px;
      line-height: 1.57;
      color: rgb(7, 14, 50);
      text-align: left;
    }
    .timesInfo {
      color: #298033;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
    }
    .timesHandler {
      flex-shrink: 0;
      margin-top: 6px;
      font-size: 14px;
      line-height: 1.57;
      color: rgb(0, 103, 139);
      text-align: left;
      position: relative;
    }
  }

  .deliveryOption {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 22px;
    background-color: rgba(255, 255, 255, 0);
  }

  .deliveryMethodLabel {
    flex-shrink: 0;
    font-size: 14px;
    line-height: 1.57;
    color: rgb(7, 14, 50);
    text-align: left;
  }

  .orderHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 42px;
    background-color: rgb(251, 251, 251);
    border: 1px solid #e3e3e7;
    border-radius: 4px 4px 0 0;
    box-shadow: 0px -3px 0px 0px rgb(227, 227, 231);
  }

  .subtotalLabel {
    flex-shrink: 0;
    margin-top: -2px;
    font-size: 12px;
    line-height: 1.17;
    color: #070e32;
    text-align: right;
    width: 15%;
  }

  .quantityLabel {
    flex-shrink: 0;
    margin-top: -2px;
    font-size: 12px;
    line-height: 1.17;
    color: #070e32;
    text-align: center;
    width: 15%;
  }

  .productInfoLabel {
    flex-shrink: 0;
    margin-top: -2px;
    font-size: 12px;
    line-height: 1.17;
    color: #070e32;
    text-align: left;
    width: 40%;
    padding-left: 21px;
  }

  .shippingNumber {
    flex-shrink: 0;
    margin-left: 2px;
    font-size: 16px;
    line-height: 1.13;
    color: rgb(0, 103, 139);
    text-align: left;
    width: 30%;
    padding-left: 24px;
  }

  :global {
    .ant-card-head-title {
      padding: 0;
      margin: 24px 24px 0px 24px;
      padding-bottom: 16px;
    }

    .ant-card-head,
    .ant-card-body {
      padding: 0;
      border: 0;
    }
  }

  &_box {
    padding: 24px;
    padding-top: 0;
  }
}
.deliveryMethod {
  height: 40px;
  line-height: 40px;
  text-indent: 30px;
}

.title {
  font-weight: 600;
  color: rgba(0, 3, 20, 0.85);
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span:last-child {
    cursor: pointer;
    font-weight: normal;
    color: rgba(25, 56, 237, 1);
    font-size: 14px;
  }
}

.info {
  color: rgba(0, 3, 20, 0.45);
  font-size: 12px;
  line-height: 17px;
}
.edit {
  height: 30px;
  margin-left: 10px;
  color: rgb(25, 56, 237);
  cursor: pointer;
}
.timeGroupitem {
  position: relative;
  padding-left: 30px;
  border-top: 1px solid rgb(223, 223, 223);
  padding-top: 10px;
  .picker {
    position: absolute;
    top: 0px;
    right: -100px;
    z-index: 999999;
  }
}

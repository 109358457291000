/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.createApproval{
  width: 1200px;
  margin: 0 auto;
  .title{
    width: 100%;
    height: 25px;
    line-height: 25px;
    margin-top: 9px;
    margin-bottom: 16px;
    .left{
      float: left;
      font-size: 16px;
      font-weight: 500;
      color: $text-085-color;
    }
  }
  .info{
    width: 100%;
    height: 134px;
    background: $bg-color-2;
    padding: 0 24px;
    border-radius: 4px;
    .infoTitle{
      height: 70px;
      line-height: 70px;
      font-size: 16px;
      font-weight: 500;
      color: $text-085-color;
    }
    .infoItems{
      display: flex;
      margin-bottom: 10px;

      .infoItem{
        flex: 1;
        display: flex;
        font-size: 14px;
        width: 480px;
        align-items: center;
        position: relative;
        .name{
          width: 84px;
          color: $text-085-color;
        }
        .small{
          width: 70px;
          color: $text-065-color;
        }
        .content{
          width: 340px;
          color: $text-085-color;
          @include ellipsis(1);
        }
        em{
          color: rgba(224,2,36,0.85);
          margin-left: 2px;
        }
        span{
          display: inline-block;
          margin-left: 4px;
        }
        .inputBorder{
          width: 370px;
          height: 32px;
          border: 1px solid $text-015-color;
          border-radius: 4px;
          position: relative;
          overflow: hidden;
          &:hover{
            border-color: $new-base-color;
          }
          &.error{
            border:1px solid #E00224;
          }
          .inputCount{
            position: absolute;
            top: 8px;
            right: 12px;
            font-size: 14px;
            color: $text-025-color;
          }
          .inputError{
            font-size: 14px;
            color: #E00224;
            margin-top: 4px;
            margin-left: 2px;
          }
        }
        input{
          border: none;
          width: 280px;
          height: 100%;
          text-indent: 12px;
        }

        .defaultBtn{
          // width: 102px;
          // height: 32px;
          // line-height: 32px;
          padding: 8px 8px;
          text-align: center;
          cursor: pointer;
          color: $text-085-color;
          border: 1px solid $text-015-color;
          border-radius: 4px;
        }
        .defaultBtn:hover,.active{
          position: relative;
          border: 1px solid $blue-new-color;
          border-radius: 4px;
          color: $blue-085-color;
          &::before {
            width: 15px;
            height: 15px;
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            background-image: url(/assets/images/orderInfo/Subscript.png);
            background-size: 100% 100%;
            background-position: 100% 100%;
          }
        }
      }
    }
  }
  .set{
    width: 100%;
    min-height: 508px;
    background: $bg-color-2;
    margin: 8px 0;
    border-radius: 4px;
    .setTitle{
      padding: 24px 24px 0 24px;
      .titleTop{
        height: 22px;
        line-height: 22px;
        display: flex;
        .topLeft{
          flex: 1;
          font-size: 16px;
          font-weight: 500;
          color: $text-085-color;
        }
        .topRight{
          cursor: pointer;
          font-size: 14px;
          font-weight: normal;
          color: $blue-new-color;
          &:hover{
            color: $blue-new-hover-color;
          }
        }
      }
      .titleTips{
        height: 17px;
        line-height: 17px;
        font-size: 12px;
        margin-top: 2px;
        color: $text-045-color;
      }
    }
    .setContent{
      overflow-x: auto;
      margin-top: 24px;
      padding-bottom: 24px;
    }
  }
  .account{
    width: 100%;
    min-height: 130px;
    background: $bg-color-2;
    padding: 24px;
    margin-bottom: 8px;
    border-radius: 4px;
    .accountTop{
      display: flex;
      align-items: center;
      .topTitle{
        flex: 1;
        font-size: 16px;
        font-weight: 500;
        color: $text-085-color;
      }
    }
    .accountContent{
      margin-top: 16px;
      font-size: 14px;
      font-weight: normal;
      color: $text-085-color;
      @include ellipsis(2);
    }
    .accountEmpty{
      width: 100px;
      height: 92px;
      margin: 32px auto 0;
      .emptyIcon{
        display: block;
        width: 52px;
        height: 63px;
        margin: 0 auto 9px;
        // background: url("../../../assets/images/empty/empty_new.svg") no-repeat center;
        background-size: cover;
      }
      .emptyContent{
        display: block;
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: normal;
        color: $text-045-color;
      }
    }
  }
  .submit{
    width: 100%;
    height: 66px;
    line-height: 66px;
    background: #ffffff;
    border-radius: 4px;
    .right{
      float: right;
      margin-right: 24px;
    }
  }
}

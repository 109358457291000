/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

.content{
  flex:1;
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100% - 198px);

  :global{
    .approval_tip .ant-tooltip-inner{
      background: rgba(255,255,255,1);
      border: 1px solid rgba(0,0,0,0.04);
      border-radius: 4px;
      box-shadow:  0 0 4px 0 rgba(0,0,0,0.08);
      color: rgba(0,0,0,0.85);
    }
    // .approval_smodal{
    //   .ant-modal-content{
    //     width: 480px;
    //     height: 181px;
    //     background: rgba(255,255,255,1);
    //     border-radius: 4px;
    //     box-shadow:  0 0 5px 0 rgba(0,0,0,0.2);
    //     .anticon-exclamation-circle{
    //       width: 24px;
    //       height: 24px;
    //       color: rgba(0,131,255,1)!important;
    //     }
    //     .ant-modal-confirm-body > .anticon{
    //       margin-right: 8px;
    //     }
    //     .ant-modal-confirm-title{
    //       font-size: 16px;
    //       font-family: PingFang SC;
    //       font-weight: 600;
    //       color: rgba(0,0,0,0.85);
    //     }
    //     .ant-modal-confirm-content{
    //       font-size: 14px;
    //       font-family: PingFang SC;
    //       font-weight: normal;
    //       color: rgba(0,0,0,0.65);
    //       margin-top: 8px;
    //     }
    //     .ant-modal-confirm-body:after{
    //       width: 480px;
    //       height: 1px;
    //       background: rgba(0,0,0,0.05);
    //     }
    //     .ant-modal-confirm-btns{
    //       .ant-btn{
    //         width: 74px;
    //         height: 32px;
    //         background: rgba(0,131,255,1);
    //         border-radius: 2px;
    //         font-size: 14px;
    //         font-family: PingFang SC;
    //         font-weight: normal;
    //         color: rgba(255,255,255,1);
    //       }
    //       .ant-btn-primary{
    //         background: rgba(255,255,255,1)!important;
    //         border: 1px solid rgba(0,0,0,0.25)!important;
    //         font-size: 14px;
    //         font-family: PingFang SC;
    //         font-weight: normal;
    //         color: rgba(0,0,0,0.85)!important;
    //       }
    //     }
    //   }
    // }
  }
  .operations{
    padding: 20px 16px 0px;
    background: #fff;
    .manage{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #DFE1E4;
      padding-bottom: 16px;
      &>div:first-child{
        line-height: 25px;
        width: 90px;
        font-size: 18px;
        font-weight: 500;
        color: $text-100-color;
      }
    }
  }

  .search{
    height: 80px;
    background: rgba(255,255,255,1);
    padding:24px 16px 0px;
    box-sizing: border-box;
    margin: 0px 0 8px 0;
    .searchForm{
      display: flex;
      :global{
        .ant-select-item-option-content{
          background:$blue-new-color;
        }
      }
      &>div:first-child{
        width: 116px;
      }
      &>div:nth-child(2){
        width: 281px;
        margin: 0 32px 0 8px;
      }
    }
    .select{
      :global{
        .ant-select-selector{
          border: 1px solid #C9CDD4;
          border-radius: 4px;
          box-shadow: none;
        }
        .ant-select-selector:hover{
          border: 1px solid $new-base-color;
        }
      }
    }
    .select_popup{
      width: 200px;
      background: red;
    }
  }

  .list{
    padding: 16px;
    box-sizing: border-box;
    background: rgba(255,255,255,1);
    padding-bottom: 83px;
    flex:1;
    .control{
      display: flex;
      align-items: center;
      font-size: 14px;
      &>div:nth-child(1) {
        color: $text-085-color;
        margin-left:16px;
        .sp{
          margin: 0 10px;
        }
        .part_c{
          color: rgba(0,3,20,0.45);
        }
      }
      &>div:nth-child(2) {
        margin: 0 8px 0 16px;
      }
    }

    .table{
      .table_titles{
        margin: 16px 0 0 0;
        background: rgba(242,242,243,1);
        font-size: 14px;
        padding:10px 0;
        padding-left: 46px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border:1px solid #DFE1E4;
        color: #595B66;
        font-weight: 500;
        .drop{
          :global{
            .ant-dropdown-menu-item{
              font-size: 14px;
              &:hover{
                background: #EFF4FE;
                border-radius: 1px 1px 0 0;
                color: $new-base-color;
              }
            }
          }
        }

        &>div{
          color:rgba(0,3,20,0.45);
          &:first-child{
            width: 300px;
            margin-right: 30px;
          }
          &:nth-child(2) {
            width: 86px;
            margin-right: 30px;
            display: flex;
            align-items: center;
            position: relative;
            img{
              margin-left: 4px;
              cursor: pointer;
            }
            .factor{
              display: none;
            }
            &:hover{
              .factor{
                display: block;
                position: absolute;
                left: 40px;
                top: 16px;
                width: 60px;

                background: rgba(255,255,255,1);
                border: 1px solid rgba(0,3,20,0.15);
                padding: 4px 5px;
              }
            }
          }
          &:nth-child(3) {
            width: 160px;
            margin-right: 30px;
          }
          &:nth-child(4) {
            width: 296px;
            margin-right: 30px;
          }
          &:nth-child(5) {
            width: 132px;
          }
        }
      }

      .item{
        ul{
          display: flex;
          align-items: center;
          font-size: 14px;
          padding: 17px 16px 17px 0;
          border-bottom: 1px solid #DFE1E4;

          &>li{
            line-height: 22px;
          }
          &:hover{
            background: #EFF4FE;
          }
          &>li:first-child{
            width: 346px;
            margin-right: 30px;

            span{
              @include ellipsis(2);
              line-height: 22px;
            }
            display: flex!important;
            align-items: center;
            // input{
            //   margin:0 16px;
            // }
          }
          &>li:nth-child(2){
            width: 86px;
            margin-right: 30px;
            @include ellipsis(2);
          }
          &>li:nth-child(3){
            width: 180px;
            margin-right: 30px;
            position: relative;
            &>div:first-child{
              @include ellipsis(2);
            }
          }
          &>li:nth-child(4){
            width: 296px;
            margin-right: 30px;
            &>span:first-child{
              margin-right: 5px;
            }
          }
          &>li:last-child{
            display: flex;
            &>a:nth-child(2) {
              margin: 0 10px;
            }
          }
        }
      }

    }

    .pagination{
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: 24px;
      bottom: 24px;
    }

    .empty{
      // padding-top: 180px;
      text-align: center;
      line-height: 196px;
      color: rgba(0,3,20,0.25);
      -webkit-user-select:none;
      -moz-user-select:none;
      -ms-user-select:none;
      user-select:none;
    }
  }
}
@media screen and (max-width: 1920px) {
  .box{
    width: 1534px !important;
  }
  .table_titles{
    &>div{
      &:nth-child(3){
        width: 310px!important;
      }
      &:nth-child(4){
        width: 296px!important;
      }
    }
  }
  .table{
    .item ul li{
      &:nth-child(3){
        width: 310px!important;
      }
      &:nth-child(4){
        width: 296px!important;
      }
    }
  }
}
//1600的挪到这里
@media screen and (max-width: 1744px) {
  .box{
    width: 1280px !important;
  }
  .table_titles{
    &>div{
      &:first-child,&:nth-child(3){
        width: 247px!important;
      }
      &:nth-child(4){
        width: 160px!important;
      }
    }
  }
  .table{
    .item ul li{
      &:first-child{
        width: 293px!important;
      }
      &:nth-child(3){
        width: 247px!important;
      }
      &:nth-child(4){
        width: 160px!important;
      }
    }
  }
}
//1280的挪到这里
@media screen and (max-width: 1424px){
  .box{
    width: 1192px !important;
  }
  .table_titles{
    &>div{
      &:first-child,&:nth-child(3){
        width: 203px!important;
      }
      &:nth-child(4){
        width: 160px!important;
      }
    }
  }
  .table{
    .item ul li{
      &:first-child{
        width: 249px!important;
      }
      &:nth-child(3){
        width: 203px!important;
      }
      &:nth-child(4){
        width: 160px!important;
      }
    }
  }
}

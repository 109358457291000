/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

  /* BatchPurchase.css */
.download-link {
  &:hover {
    text-decoration: underline;
  }
}
.bulkPurchaseContainer {
  padding: 20px 30px;
  margin: 0 auto;
  width: 1200px;
  font-size: 14px;
  background: #fff;
  box-sizing: content-box;

  .batchList_file_result_tip {
    font-size: 16px;
    margin: 15px 0 15px 8px;
    line-height: 30px;
    span {
      color: #00678B;
    }
  }

  .batchList_main_font {
    color: #333;
  }
  .batchList_lower_font {
    color: rgba(0, 0, 0, 0.45);
  }

  .batchList {
    display: flex;
    justify-content: space-between;
  }
  .batchList_file_upload {
    width: 49%;
    height: 340px;
    padding: 15px 24px 24px;
    background-color: #f0f2f5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    .batchList_main_content {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      background-color: #fff;
      border-radius: 5px;
      &>span {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        background: #fff;
        border: 1px dashed #d9d9d9;
        border-radius: 2px;
        cursor: pointer;
        transition: border-color 0.3s;
        .ant-upload.ant-upload-drag {
          background: #fff;
        }
        .ant-upload {
          padding-top: 0;
        }
      }
      .batchList_main_upload_tip {
        color: rgba(0, 3, 20, 0.45);
        font-size: 14px;
        margin-top: 8px;
        width: 100%;
        text-align: center;
      }
      .batchList_main_upload_autp {
        background-color: #fff;
        border-radius: 5px;
        width: 50%;
        height: 100%;
      }
      .batchList_main_upload_autp_tip {
        flex-grow: 1;
        padding: 0 5px 0 10px;
        height: 100%;
        margin-top: -25px;
        .ant-list-item-meta-description {
          font-size: 14px;
        }
      }
    }
    .batchList_main_content_right {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      border-radius: 5px;
      .batchList_main_upload_autp {
        background-color: #fff;
        border-radius: 5px;
        width: 55%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .batchList_main_textarea_controller {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #fff;
          min-height: 47px;
          flex-grow: 0;
          padding: 10px 7px;
          flex-wrap: wrap;
          
          .batchList_lower_font {
            color: rgba(0, 0, 0, 0.45);
            word-break: break-all;
            margin-right: 20px;
            margin-bottom: 5px;
          }
          
          .batchList_lower_ctrol {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 5px;
            &>span {
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .batchList_main_upload_autp_tip {
        flex-grow: 1;
        padding: 0 5px 0 10px;
        height: 100%;
        margin-top: -25px;
        .ant-list-item-meta-description {
          font-size: 14px;
        }
      }
    }
    .batchList_title {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      margin-bottom: 15px;
      line-height: 25px;
      color: rgba(0, 3, 20, 0.45);
      .batchList_title_blod {
        color: rgba(0, 0, 0, 0.88);
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .batch-upload-card {
    background-color: #f0f2f5;
    border-radius: 8px;
    padding: 24px;
  }
  
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  
    .title {
      font-size: 24px;
      font-weight: bold;
      margin-right: 16px;
    }
  
    .description {
      font-size: 14px;
      color: #595959;
      margin-right: 8px;
    }
  
    .download-link {
      font-size: 14px;
      color: #1890ff;
    }
  }

  .mainTitle {
    font-size: 24px;
    font-weight: bold;
    color: black;
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

.modalMessage {
  font-size: 16px;
  margin-bottom: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  line-height: 1.5;
}
.modalMessageTitle {
  color: rgba(0, 0, 0, 0.88);
  margin-left: 12px;
}

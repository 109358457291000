/* 设计规范 全局变量配置 */

/* 字号粗细 */
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;

$new-base-color: #1938ED; // 导航栏颜色
/* 颜色 */
$bg-color: #f6f6f6; // 布局背景
$bg-color-1:#f7f7f7;
$bg-color-2:#ffffff;

$border-color-1:#e1e1e1;
$price-color: #033e9e; // 价格
$a-color: #006ae0; // 文字链
$btn-color: #0d47a0;
$remind-color: #ff7c00; // 提醒
$successful-color: #3b4; // 例：校验成功
$white-bg-color: #fff; // 白色背景
$text-3-color: #333;
$text-6-color: #666;
$text-9-color: #999;
$text-c-color: #ccc;
$text-a-color:#aaa;

$text-100-color:rgba(0,3,20,1);
$text-085-color:rgba(0,3,20,0.85);
$text-065-color:rgba(0,3,20,0.65);
$text-045-color:rgba(0,3,20,0.45);
$text-025-color:rgba(0,3,20,0.25);
$text-015-color:rgba(0,3,20,0.15);
$text-003-color:rgba(0,3,20,0.03);
$background-100-color:#F5F6FA;
$background-005-color:rgba(0,3,20,0.05);
$blue-new-color: #1938ED;
$blue-new-hover-color: #435DF0;
$blue-085-color: rgba(25,56,237,0.85);
$blue-045-color: rgba(25,56,237,0.45);
$blue-005-color: rgba(25,56,237,0.05);

$red-color: #e50c00;
$blue-color: #1938ED;
$blue-color-hover: #435DF0;


$table-header:#F7F7F8;

/* 通用动画 */
$common-animation: all linear 0.1s;
$opacity-animation: opacity ease 0.5s;
$common-slow-animation: all linear 0.3s;

/* 楼层间隔 */
$floor-margin: unitpx(40);

/* 卡片样式 */
// 大圆角
$big-radius: unitpx(10);
// 基础投影
$basic-shadow: 0 unitpx(4) unitpx(20) rgba(0, 0, 0, 0.08);
$small-shadow: 0 unitpx(4) unitpx(15) rgba(0, 0, 0, 0.04);

@mixin TitleArrowHoverMixin {
  transform: translateX(5px);

  circle[id='椭圆形'] {
    fill: $brand-color;
  }
}

@mixin CardHover {
  transform: translateY(unitpx(-3));
}

@mixin TextEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis ($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin background-image ($url) {
  background-image: var($url);
  background-position: center;
  background-repeat: no-repeat;
}

$jdios-resource-path: 'https://jdios.jdindustry.com';

@mixin background-image-cdn($name) {
  background-image: url($jdios-resource-path + '/' + $name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

$red-color: #e50c00;

@mixin half-pixel-border($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4) {
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 201%;
    height: 201%;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
    border-radius: $raduis1 * 2 $raduis2 * 2 $raduis3 * 2 $raduis4 * 2;
    
    @if $position == all {
      border: 1px solid $color;
    } @else if $position == top {
      border-top: 1px solid $color;
    } @else if $position == right {
      border-right: 1px solid $color;
    } @else if $position == bottom {
      border-bottom: 1px solid $color;
    } @else if $position == left {
      border-left: 1px solid $color;
    }
  }
}

@mixin half-pixel-border-with-dpr($color: #000, $position: all, $raduis1, $raduis2, $raduis3, $raduis4 ) {
  // 默认 1x 设备
  border: 1px solid $color;
  border-radius: $raduis1 $raduis2 $raduis3 $raduis4;

  // 2x 设备
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid $color;
    border-radius: $raduis1 $raduis2 $raduis3 $raduis4;
  }

  // 3x 设备
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi) {
    @include half-pixel-border($color, $position, $raduis1, $raduis2, $raduis3, $raduis4);
  }
}

@charset "utf-8";

@import './config.scss';

$browser: null;

// @font-face {
//   font-family: JDZHENGHEI;
//   src: url('../font/JDZhengHei-01-Regular.ttf') format('ttf'), url('../font/JDZhengHei-01-Regular.eot') format('eot'),
//     url('../font/JDZhengHei-01-Regular.svg') format('svg'), url('../font/JDZhengHei-01-Regular.woff') format('woff');
// }

* {
  font-family: Roboto-Regular, 'Helvetica Neue', Helvetica, Tahoma, Arial, sans-serif !important;
}
body.vi-container {
  * {
    font-family: Times new roman, Roboto-Regular, 'Helvetica Neue', Helvetica, Tahoma, Arial, sans-serif !important;
  }
}

.ant-steps {
  .ant-steps-item-icon {
    border-color: transparent !important;
    background-color: #bfc0c4 !important;
    .ant-steps-icon {
      color: #fff !important;
      .ant-steps-icon-dot {
        background: inherit !important;
      }
    }
  }
  .ant-steps-item-finish {
    .ant-steps-item-icon {
      background-color: #00b960 !important;
    }
    .ant-steps-item-tail {
      &::after {
        background-color: #00b960 !important;
      }
    }
  }
}

.underText {
  cursor: pointer;
  &:hover {
    color: #00678b !important;
    text-decoration: underline;
  }
}

.ant-btn-color-dangerous.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled) {
  &:hover {
    background-color: #FFEDED;
  }
}

:where(.css-dev-only-do-not-override-jw5fsa).ant-btn >span:not(:only-child) {
  align-self: unset;
}

.productNameText {
  font-weight: 500;
  color: #00678b;
  &:hover {
    text-decoration: underline;
  }
}

.wrapBtn {
  max-width: 90%;
  white-space: wrap;
  min-height: 32px;
  height: auto;
  line-height: 1.2;
}

:where(.css-dev-only-do-not-override-5dj4ou).ant-btn-variant-text:not(:disabled):not(.ant-btn-disabled):hover {
  text-decoration: underline;
}

:where(.css-dev-only-do-not-override-1l4x6i0).ant-btn-color-dangerous.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
:where(.css-dev-only-do-not-override-1l4x6i0).ant-btn-color-dangerous.ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  background: #ffeded;
}

// :where(.css-dev-only-do-not-override-77tu7h).ant-message {
//   top: 0!important;
// }
%display {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
%text-indent {
  font-size: 0;
  text-indent: -99999em;
  overflow: hidden;
}
%box-sizing {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
// 绝对居中
@mixin center($width, $height) {
  position: absolute;
  left: 50%;
  top: 50%;
  width: $width;
  height: $height;
  margin: (-$height / 2) 0 0 (-$width / 2);
}
// 设置动画名称
@mixin animation($aniName) {
  -webkit-animation: $aniName;
  -moz-animation: $aniName;
  -o-animation: $aniName;
  animation: $aniName;
}
// 设置延迟执行时间
@mixin animation-delay($time) {
  -webkit-animation-delay: $time;
  -moz-animation-delay: $time;
  -o-animation-delay: $time;
  animation-delay: $time;
}
// 设置阴影
@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -o-box-shadow: $shadow;
  box-shadow: $shadow;
}
// 圆角
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}
// 设置过渡
@mixin transition($transition...) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}
// 设置旋转位置
@mixin transform-origin($origin...) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin transform($transform...) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

// 设置关键帧
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    $browser: '-webkit-';
    @content;
  }

  @-moz-keyframes #{$name} {
    $browser: '-moz-';
    @content;
  }

  @-o-keyframes #{$name} {
    $browser: '-o-';
    @content;
  }

  @keyframes #{$name} {
    $browser: '';
    @content;
  }
}

/* ********************重置样式 reset******************** */
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  //font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  min-width: 1076px;
  overflow-x: auto;
}

ol,
ul {
  margin: 0;
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::after,
blockquote::before,
q::after,
q::before {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  /* -webkit-box-sizing: border-box; */
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

::after,
::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* 清除点击出现虚拟框 */
a {
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:focus {
    outline: 0;
  }
}

a img {
  border: none;
}

/* 清除浮动 */
.clearfix {
  *zoom: 1;

  &::after {
    display: block;
    content: '\200B';
    clear: both;
    height: 0;
  }
}

#loading {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  margin-left: -10px;
  z-index: 999;
  .loadwrapper {
    position: absolute;
    left: 50%;
    top: 40%;
    margin-left: -10px;
  }
}

.ispWrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .ispContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

iframe[style] {
  display: none;
}

.rc-input-number-handler-up-disabled,
.rc-input-number-handler-down-disabled {
  cursor: not-allowed;
  color: #bfc0c4;
}
.rc-input-number-input {
  width: 40px;
}

.cart-number {
  position: relative;
  width: 110px;
  border: 1px solid rgba(0, 3, 20, 0.15);
  border-radius: 3px;
  overflow: hidden;
  font-size: 14px;
  .cart-number-handler {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 32px;
    background: rgba(245, 246, 250, 1);
    cursor: pointer;
    font-weight: 500;
  }
  .cart-number-handler-up {
    right: 0;
  }
  .cart-number-handler-down {
    left: 0;
  }
  .cart-number-input-wrap {
    padding: 0 30px;
    input {
      padding: 0;
      border: 0;
      width: 72px;
      height: 32px;
      text-align: center;
      outline: 0;
      font-weight: 500;
    }
  }
}
.cart-number-disabled {
  background: #f5f6fa;
  color: #bfc0c4;
  .cart-number-handler {
    cursor: not-allowed !important;
    color: #bfc0c4;
    background: #f5f6fa !important;
  }
}
.cart-number-handler-up-disabled,
.cart-number-handler-down-disabled {
  cursor: not-allowed;
  color: #bfc0c4;
}

.rc-input-number {
  position: relative;
  width: 100px;
  border: 1px solid #ccc;
  border-radius: 3px;
  overflow: hidden;
}
.rc-input-number-handler {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: #f1f1f1;
  cursor: pointer;
}
.rc-input-number-handler-up {
  right: 0;
}
.rc-input-number-handler-down {
  left: 0;
}
.rc-input-number-input-wrap {
  padding: 0 30px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  input {
    padding: 0;
    border: 0;
    width: 36px;
    height: 30px;
    text-align: center;
    outline: 0;
  }
}
input,
textarea,
select,
a:focus {
  outline: none;
}

.ant-anchor-wrapper {
  background-color: #fff;
}
